export const BIZML_DOMAIN_SUFFIX = 'bizml.ru';

export const TOOLTIP =
	'Чтобы сохранить домен на\u00A0bizml.ru и\u00A0свои данные, купите подписку в\u00A0течение 30 дней с\u00A0момента активации тестового периода';

export const MAX_ATTEMPTS_COUNT_FOR_COMPANY_BIZML_CREATE_REQUEST = 4;

export const ERROR_RESPONSE_CODES = {
	duplicate: 91,
} as const;

export const RANDOM_INTEGER_SEGMENT = {
	min: 1,
	max: 99,
} as const;

export const INPUT_COMPANY_NAME_MAX_LENGTH = 40;
