import {action, computed, observable} from 'mobx';

import {injectProperty} from '@/container/injections';
import {BaseModel} from '@/mobx/business-models/BaseModel';
import {IApiService} from '@/services/api/IApiService';
import {IHttpAdapterResponse} from '@/services/http-adapter/interfaces';

export class BaseCollection<TModel extends typeof BaseModel, IModel = InstanceType<TModel>> {
	@injectProperty(IApiService) protected apiService!: IApiService;

	private readonly _collection: Map<string, IModel> = observable.map({}, {deep: false});
	private readonly _modelClass: TModel;

	protected isLoaded = false;

	constructor(modelClass: TModel) {
		this._modelClass = modelClass;
	}

	@action
	protected apply<T extends IHttpAdapterResponse<any[]>>(response: T) {
		try {
			response.data.forEach((payload) => {
				if (payload.id) {
					const modelInstance = new this._modelClass<T['data']>(payload);
					this._collection.set(payload.id, modelInstance as unknown as IModel);
				} else {
					throw new Error('No id provided in payload');
				}
			});
		} catch (error) {
			// todo: добавить логирование ошибок
			console.error(error);
			throw new Error(
				`Cannot apply response data to model=${this._modelClass.name}. Cause: ${error.message}`,
			);
		}
	}

	@computed
	public get items(): IModel[] {
		return Array.from(this._collection.values());
	}

	public peek(id: string) {
		return this._collection.get(id);
	}

	@action
	public async load() {
		throw new Error('The load() method is not implemented');
	}

	@action
	public destroy() {
		this._collection.clear();
	}
}
