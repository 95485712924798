export function getCSRFToken(): string {
	const key = 'csrftoken';

	const targetKey = new RegExp(`^\\s*${key}=\\s*(.*?)\\s*$`);
	let cookieValue = null;
	const cookies = document.cookie.split(';');

	cookies.some(function (cookie) {
		cookieValue = cookie.match(targetKey);

		if (cookieValue) {
			cookieValue = cookieValue[1];

			return true;
		}

		return false;
	});

	return cookieValue;
}
