import {JSPrimitiveType, NoneType} from '@/utilites/utility-types';

export function isString(target: any): target is string {
	return typeof target === 'string';
}

export function isIsoString(target: any): target is string {
	if (isString(target) && target.length <= 30) {
		return !isNaN(Date.parse(target));
	}
	return false;
}

export function isNumber(target: any): target is number {
	return typeof target === 'number' && !isNaN(target);
}

export function isBoolean(target: any): target is boolean {
	return typeof target === 'boolean';
}

export function isUndefined(target: any): target is undefined {
	return typeof target === 'undefined';
}

export function isNull(target: any): target is null {
	return target === null;
}

export function isNotNull<T>(target: T): target is Exclude<T, null> {
	return target !== null;
}

export function isDefined<T>(target: T): target is Exclude<T, undefined> {
	return typeof target !== 'undefined';
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function isFunction(target: any): target is Function {
	return typeof target === 'function';
}

export function isNone(target: any): target is NoneType {
	return typeof target === 'undefined' || target === null;
}

export function isPrimitive(target: any): target is JSPrimitiveType | Date {
	if (target === null || target === undefined) {
		return true;
	}
	const typeOfTarget = typeof target;
	return (
		typeOfTarget === 'string' ||
		typeOfTarget === 'number' ||
		typeOfTarget === 'boolean' ||
		target instanceof Date
	);
}

export function isGuid(target: any): target is string {
	// eslint-disable-next-line sonarjs/no-collapsible-if
	if (target.length > 0) {
		if (
			!/^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/.test(
				target,
			)
		) {
			return false;
		}
	}
	return true;
}
