import type React from 'react';

import type {AppRootModel} from '@/mobx/auxiliary-models/app/AppRootModel';
import type {MaybeMissing} from '@/utilites/utility-types';

export abstract class IPortalRendererService {
	public abstract registerRootModel(appRootModel: AppRootModel): void;
	public abstract renderElement(elementIdentifier: string, element: React.ReactNode): void;
	public abstract getElement(elementIdentifier: string): MaybeMissing<React.ReactElement>;
	public abstract unmountElement(elementIdentifier: string): void;
	public abstract unmountAllElements(): void;
	public abstract waitForRootModel(): Promise<AppRootModel>;
}
