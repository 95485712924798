import {interfaces, LazyServiceIdentifer} from 'inversify';
import getDecorators from 'inversify-inject-decorators';

import APPLICATION_CONTAINER from '../../inversify.config';

export const DECORATORS = getDecorators(APPLICATION_CONTAINER, true);

export const injectProperty = function (serviceIdentifier: interfaces.ServiceIdentifier<any>) {
	const original = DECORATORS.lazyInject(serviceIdentifier);
	return function (this: any, proto: any, key: string): any {
		original.call(this, proto, key);
		return Object.getOwnPropertyDescriptor(proto, key);
	};
};

export const injectNamedProperty = function (
	serviceIdentifier: interfaces.ServiceIdentifier<any>,
	named: string,
) {
	const original = DECORATORS.lazyInjectNamed(serviceIdentifier, named);
	return function (this: any, proto: any, key: string): any {
		original.call(this, proto, key);
		return Object.getOwnPropertyDescriptor(proto, key);
	};
};

export const injectTaggedProperty = function (
	serviceIdentifier: interfaces.ServiceIdentifier<any>,
	key: string,
	value: any,
) {
	const original = DECORATORS.lazyInjectTagged(serviceIdentifier, key, value);
	return function (this: any, proto: any, innerKey: string): any {
		original.call(this, proto, innerKey);
		return Object.getOwnPropertyDescriptor(proto, innerKey);
	};
};

export const injectMultipleProperty = function (serviceIdentifier: interfaces.ServiceIdentifier<any>) {
	const original = DECORATORS.lazyMultiInject(serviceIdentifier);
	return function (this: any, proto: any, key: string): any {
		original.call(this, proto, key);
		return Object.getOwnPropertyDescriptor(proto, key);
	};
};

export const injectArguments = <DecoratedFn extends (...args: any) => any>(
	injectionTokens: Array<interfaces.ServiceIdentifier<any> | LazyServiceIdentifer>,
	receiverFunc: (...args: any) => DecoratedFn,
): DecoratedFn => {
	return function (this: any, ...args: any): any {
		const injections = injectionTokens.map((dependency) => {
			if (dependency instanceof LazyServiceIdentifer) {
				return APPLICATION_CONTAINER.get(dependency.unwrap());
			}
			return APPLICATION_CONTAINER.get(dependency);
		});

		return receiverFunc(...injections).call(this, ...args);
	} as DecoratedFn;
};
