import {action, makeAutoObservable} from 'mobx';

import {injectProperty} from '@/container/injections';
import {IApiService} from '@/services/api/IApiService';
import {Feature, GetMeSuccessResponse} from '@/services/api/interfaces/me';
import {HttpAdapterResponseExtractor} from '@/services/http-adapter/HttpAdapterResponseExtractor';

export class MeModel implements GetMeSuccessResponse {
	@injectProperty(IApiService) protected apiService!: IApiService;

	features: Feature[];
	has_verified_phone: boolean;
	is_trial_available: boolean;
	is_mail: boolean;
	type?: any;
	phone?: string | number;
	email?: string;

	constructor() {
		makeAutoObservable(this);
		this.load();
	}

	@action
	public async load() {
		const response = await this.apiService.getMe();
		const responseExtractor = new HttpAdapterResponseExtractor(response);
		if (responseExtractor.isSuccessResponse) {
			const data = responseExtractor.getResponseData();
			this.features = data.features;
			this.has_verified_phone = data.has_verified_phone;
			this.is_trial_available = data.is_trial_available;
			this.is_mail = data.is_mail ?? true;
			this.type = data.type;
			this.phone = data.phone;
			this.email = data.email;
			return;
		}
		const errorMessage = responseExtractor.getErrorMessage();
		const error = new Error(errorMessage);
		console.error(error);
	}
}

export const MeStore = new MeModel();
