import {BaseModel, Model} from '@/mobx/business-models/BaseModel';
import {Domain} from '@/services/api/interfaces/domains';

@Model
export class DomainModel extends BaseModel<DomainModel> implements Domain {
	id: string;
	name: string;
	confirmed: boolean;
	confirmation_type: string;
	confirmed_at: string;
	migration: boolean;
	mx_status: number;
	spf_status: number;
	shared_address_book: boolean;
	dkim: {
		status: number;
		subdomain: string;
		value: string;
	};

	mxs?: {
		records?: Array<{
			priority: number;
			record: string;
		}>;
		next_at?: string;
		checked_at?: string;
	};

	created_at: string;
	user_count: number;

	isConfirmed() {
		return this.confirmed === true;
	}
}
