import classNames from 'classnames';
import {ForwardedRef, KeyboardEventHandler, MouseEventHandler, PropsWithChildren, useEffect} from 'react';

import {PortalContainer} from '@/components/helpers/portal/interfaces';

import {Portal} from '../../helpers/portal/Portal';
import styles from './DialogWrap.module.css';

interface DialogWrapProps {
	onKeyDown: KeyboardEventHandler<HTMLDivElement>;
	onWrapMouseDown?: MouseEventHandler<HTMLDivElement>;
	onContainerMouseDown?: (event: MouseEvent) => void;
	elRef: ForwardedRef<HTMLDivElement>;
	container: PortalContainer;
	disableBackdropScroll?: boolean;
	backdrop?: boolean;
}

export function DialogWrap({
	children,
	onWrapMouseDown,
	onContainerMouseDown,
	onKeyDown,
	elRef,
	container,
	backdrop,
	disableBackdropScroll,
}: PropsWithChildren<DialogWrapProps>) {
	useEffect(() => {
		if (onContainerMouseDown && container) {
			container.onmousedown = onContainerMouseDown;
			return () => {
				container.onmousedown = undefined;
			};
		}
	}, [container, onContainerMouseDown]);

	const disableBackdrop = !backdrop;
	return (
		<Portal container={container}>
			<div
				ref={elRef}
				className={classNames(styles.root, styles.stateOpened, {
					[styles.stateDisabledBackdrop]: disableBackdrop,
					[styles.stateDisabledBackdropScroll]: disableBackdropScroll,
				})}
				onKeyDown={onKeyDown}
				onMouseDown={onWrapMouseDown}
				role="presentation"
			>
				{children}
			</div>
		</Portal>
	);
}
