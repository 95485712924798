import type React from 'react';

import type {AppRootModel} from '@/mobx/auxiliary-models/app/AppRootModel';
import type {MaybeMissing, Nullable} from '@/utilites/utility-types';

import {ServiceBase} from '../ServiceBase';
import type {IPortalRendererService} from './IPortalRendererService';

const MODEL_RESOLVE_TIMEOUT = 200;
const MODEL_REJECT_TIMEOUT = 5000;

export class PortalRendererService extends ServiceBase implements IPortalRendererService {
	private rootModel: Nullable<AppRootModel>;

	constructor() {
		super();
		this.rootModel = null;
	}

	public registerRootModel(rootModel: AppRootModel): void {
		this.rootModel = rootModel;
	}

	public renderElement(elementIdentifier: string, element: React.ReactElement): void {
		if (this.rootModel) {
			this.rootModel.renderServiceElement(elementIdentifier, element);
		} else {
			// todo: replace with logger
			console.error(
				`Trying to render service child while application root model is not registered in PortalRendererService.`,
			);
		}
	}

	public getElement(elementIdentifier: string): MaybeMissing<React.ReactElement> {
		return this.rootModel?.getServiceElement(elementIdentifier);
	}

	public unmountElement(elementIdentifier: string): void {
		if (this.rootModel) {
			this.rootModel.unmountServiceElement(elementIdentifier);
		} else {
			// todo: replace with logger
			console.error(
				`Trying to unmount service child while application root model is not registered in PortalRendererService.`,
			);
		}
	}

	public unmountAllElements() {
		if (this.rootModel) {
			this.rootModel.unmountAllElements();
		}
	}

	public waitForRootModel(): Promise<AppRootModel> {
		return new Promise((resolve, reject) => {
			const checkRootModel = setInterval(() => {
				if (this.rootModel) {
					clearInterval(checkRootModel);
					resolve(this.rootModel);
				}
			}, MODEL_RESOLVE_TIMEOUT);

			setTimeout(() => {
				clearInterval(checkRootModel);
				reject(new Error('Timeout: rootModel was not set in time.'));
			}, MODEL_REJECT_TIMEOUT);
		});
	}
}
