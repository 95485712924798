import {UAEvent} from '../interfaces';

export function getUAParams(payload?: UAEvent['payload']): UAParams | undefined {
	if (!payload) {
		return;
	}

	const {category, action, label} = payload;

	const params: UAParams = {
		event_category: category,
		event_action: action,
	};

	if (label) {
		params.event_label = label;
	}

	return params;
}
