import {forwardRef, MouseEventHandler, PropsWithChildren, useMemo} from 'react';

import {getLinkProps} from '@/components/base/link/get-link-props';
import {urlParser} from '@/utilites/url-parser/url-parser';

const {origin: currentOrigin} =
	typeof window === 'undefined' ? {origin: null} : urlParser(window.location.href);

export interface LinkProps {
	href: string;
	target?: '_self' | '_blank' | '_parent' | '_top';
	'aria-label'?: string;
	id?: string;
	className?: string;
	title?: string;

	onClick?: MouseEventHandler<HTMLAnchorElement>;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
}

export const Link = forwardRef<HTMLAnchorElement, PropsWithChildren<LinkProps>>(function Link(
	{
		onClick,
		onMouseEnter,
		onMouseLeave,
		href,
		target,
		children,
		'aria-label': ariaLabel,
		id,
		className = null,
		title,
	},
	ref,
) {
	// TODO нужно ли это? либо некстовый Link такое умеет https://jira.vk.team/browse/BIZ-9743
	// TODO также могут быть проблемы с SSR
	const {target: defaultTarget, rel} = useMemo(
		function () {
			const {origin: linkOrigin} = typeof window === 'undefined' ? {origin: null} : urlParser(href);
			return getLinkProps(href, linkOrigin !== currentOrigin);
		},
		[href],
	);

	return (
		<a
			href={href}
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			target={target || defaultTarget}
			rel={rel}
			aria-label={ariaLabel}
			id={id}
			className={className}
			title={title}
			ref={ref}
		>
			{children || href}
		</a>
	);
});
