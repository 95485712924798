import React from 'react';

import {Button} from '@/components/base/button/Button';
import {DialogTitle} from '@/components/base/dialog/DialogTitle';
import {Typography, VARIANTS} from '@/components/base/typography/Typography';

import styles from './AddPhone.module.css';

type AddPhoneProps = {
	onAddClick: () => void;
};

export const AddPhone = ({onAddClick}: AddPhoneProps) => {
	return (
		<div className={styles.container}>
			<DialogTitle>Добавьте номер телефона</DialogTitle>
			<Typography variant={VARIANTS.text15} className={styles.label}>
				Номер нужен, чтобы создать домен для личного кабинета
			</Typography>
			<Button type={'button'} onClick={onAddClick} fullWidth>
				Добавить номер
			</Button>
		</div>
	);
};
