import {IHttpAdapterRequestConfig, IHttpAdapterResponse} from '@/services/http-adapter/interfaces';

export abstract class IHttpAdapterService {
	public abstract post<TD, TR = unknown>(
		url: string,
		data?: TD,
		config?: IHttpAdapterRequestConfig,
	): Promise<IHttpAdapterResponse<TR>>;

	public abstract get<TR = unknown>(
		url: string,
		config?: IHttpAdapterRequestConfig,
	): Promise<IHttpAdapterResponse<TR>>;
}
