import {PropsWithChildren} from 'react';
import ReactDOM from 'react-dom';

import {PortalContainer} from './interfaces';

export function getContainer(container) {
	let node = null;

	if (container === undefined && typeof window !== 'undefined') {
		return document.body;
	}

	if (typeof container === 'function') {
		node = container();
	}

	if (node === undefined) {
		node = container;
	}

	return node;
}

interface PortalProps {
	container: PortalContainer;
}

export function Portal({children, container}: PropsWithChildren<PortalProps>) {
	container = container || getContainer(container);

	if (!container) {
		return null;
	}

	return ReactDOM.createPortal(children, container);
}
