import {action, makeAutoObservable} from 'mobx';

import {injectProperty} from '@/container/injections';
import {IApiService} from '@/services/api/IApiService';
import type {GetPSRegStatusSuccessResponse} from '@/services/api/interfaces/ps-reg-status';
import {HttpAdapterResponseExtractor} from '@/services/http-adapter/HttpAdapterResponseExtractor';

export class PSRegStatusModel implements GetPSRegStatusSuccessResponse {
	@injectProperty(IApiService) protected apiService!: IApiService;

	constructor() {
		makeAutoObservable(this);
	}

	private _domain_name: string;
	private _linkUID: string;
	isLoaded = false;

	get domain_name() {
		return this._domain_name;
	}

	get linkUID() {
		return this._linkUID;
	}

	isPartnerUser() {
		return !!this._linkUID;
	}

	hasDefaultDomain() {
		return !!this._domain_name;
	}

	@action
	public async load(linkUID: string) {
		this._linkUID = linkUID;
		const response = await this.apiService.getPSRegistrationStatus(linkUID);
		const responseExtractor = new HttpAdapterResponseExtractor(response);
		if (responseExtractor.isSuccessResponse) {
			const data = responseExtractor.getResponseData();
			this._domain_name = data.domain_name;
			this.isLoaded = true;
			return;
		}
		const errorMessage = responseExtractor.getErrorMessage();
		const error = new Error(errorMessage);
		console.error(error);
	}
}

export const PSRegStatusStore = new PSRegStatusModel();
