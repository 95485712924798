import {PropsWithChildren} from 'react';

import {Typography, VARIANTS} from '@/components/base/typography/Typography';

import styles from './DialogDescription.module.css';

export function DialogDescription({children}: PropsWithChildren<any>) {
	return (
		<Typography variant={VARIANTS.text} className={styles.description}>
			{children}
		</Typography>
	);
}

DialogDescription.displayName = 'DialogDescription';
