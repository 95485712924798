import {interfaces} from 'inversify/lib/interfaces/interfaces';
import {useMemo} from 'react';

import APPLICATION_CONTAINER from '../../inversify.config';

export function useInjection<T>(id: string | symbol | interfaces.Newable<T> | interfaces.Abstract<T>): [T] {
	return useMemo<[T]>(() => {
		return [APPLICATION_CONTAINER.get<T>(id)];
	}, [id]);
}
