import {createSafetyStorages} from '@mail/storage';

const [safetyLocalStorage, safetySessionStorage, safetyRuntimeStorage] = createSafetyStorages({
	// todo: #logger, добавить логгер
	// onerror: (storage, method, error, key, value) => {
	// 	logger.error({key, value, error}, `Storage "${storage}#${method}" failed`);
	// },
});

export {safetyLocalStorage, safetySessionStorage, safetyRuntimeStorage};
