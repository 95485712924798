enum TestDomainPrefix {
	tb1 = 'test-testbiz-',
	tb2 = 'test-testbiz2-',
	tb3 = 'test-testbiz3-',
}

// todo: исправить формулировку ошибок на бэкенде

const getInvalidDomainTbText = (prefix: TestDomainPrefix) =>
	`В данном тестовом окружении доступны для регистрации только доменные имена с префиксом «${prefix}»`;
const getInvalidDomainTbTextReplaced = (prefix: TestDomainPrefix) => `Домен должен начинаться с ${prefix}`;

export function errorTextReplacer(errorMessage: string) {
	switch (errorMessage) {
		case getInvalidDomainTbText(TestDomainPrefix.tb1):
			return getInvalidDomainTbTextReplaced(TestDomainPrefix.tb1);
		case getInvalidDomainTbText(TestDomainPrefix.tb2):
			return getInvalidDomainTbTextReplaced(TestDomainPrefix.tb2);
		case getInvalidDomainTbText(TestDomainPrefix.tb3):
			return getInvalidDomainTbTextReplaced(TestDomainPrefix.tb3);
		default:
			return errorMessage;
	}
}
