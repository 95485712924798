import React, {Children, cloneElement, isValidElement, Key, PropsWithChildren} from 'react';

import {Dialog, DialogProps} from '@/components/base/dialog/Dialog';

export const LIGHTBOX_IDENTIFIER = Symbol.for('$$VK_WS_Landings_Lightbox');

export const isLightbox = (element: React.ReactElement): boolean => {
	return element.props.identifier === LIGHTBOX_IDENTIFIER;
};

export interface ICommonLightboxOptionsInternal extends DialogProps {
	onClose: () => void;
	key: Key;
	identifier?: symbol;
	dataTid?: string;
	needWrapper: boolean;
}

export class Lightbox extends React.Component<PropsWithChildren<ICommonLightboxOptionsInternal>> {
	static defaultProps = {
		identifier: LIGHTBOX_IDENTIFIER,
	};

	render() {
		const {children, needWrapper, ...modalProps} = this.props;

		if (needWrapper) {
			return (
				<Dialog {...modalProps} open>
					{children}
				</Dialog>
			);
		}

		if (isValidElement(children)) {
			return cloneElement(children, modalProps);
		}

		return Children.only(children);
	}
}
