import {Utm} from '@mail/xray';

import {urlParser} from '@/utilites/url-parser/url-parser';

export const getUtmMetricsFromUrl = (): Utm | undefined => {
	const params = urlParser(location.href).searchParams;

	const utm: Utm = {
		source: params.get('utm_source') || '',
		medium: params.get('utm_medium') || '',
		campaign: params.get('utm_campaign') || '',
		content: params.get('utm_content') || '',
		term: params.get('utm_term') || '',
	};

	if (Object.values(utm).some(Boolean)) {
		return utm;
	}

	return undefined;
};
