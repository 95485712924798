import {forwardRef, MouseEventHandler, PropsWithChildren} from 'react';

import type {ARIAControls, ARIAExpanded, ARIAHasPopup} from './interfaces-a11y';

export interface NativeButtonProps {
	id?: string;
	name?: string;
	value?: string;
	className?: string;

	type?: 'button' | 'submit' | 'reset';
	tabIndex?: -1 | 0;
	'aria-label'?: string;
	'aria-haspopup'?: ARIAHasPopup;
	'aria-controls'?: ARIAControls;
	'aria-expanded'?: ARIAExpanded;
	title?: string;

	disabled?: boolean;

	onClick?: MouseEventHandler<HTMLButtonElement>;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
}

export const NativeButton = forwardRef<HTMLButtonElement, PropsWithChildren<NativeButtonProps>>(
	function NativeButton(
		{
			children,
			id,
			name,
			value,
			className = null,

			type = 'button',
			tabIndex = null,
			'aria-label': ariaLabel = null,
			'aria-haspopup': ariaHaspopup,
			'aria-controls': ariaControls,
			'aria-expanded': ariaExpanded,
			title,

			disabled = false,

			onClick,
			onMouseEnter,
			onMouseLeave,
		},
		ref,
	) {
		return (
			<button
				id={id}
				name={name}
				value={value}
				className={className}
				type={type}
				tabIndex={disabled === false ? tabIndex : -1}
				aria-label={ariaLabel}
				aria-haspopup={ariaHaspopup}
				aria-controls={ariaControls}
				aria-expanded={ariaExpanded}
				title={title}
				disabled={disabled}
				onClick={disabled === false ? onClick : null}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				ref={ref}
			>
				{children}
			</button>
		);
	},
);
