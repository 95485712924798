import {Nullable} from '@/utilites/utility-types';

import {CookieAttributes} from './interfaces';

export abstract class ICookieService {
	public abstract get(key: string): Nullable<string>;
	public abstract set(key: string, value: string | number | boolean, options?: CookieAttributes): boolean;
	public abstract has(key: string): boolean;
	public abstract remove(key: string): boolean;
}
