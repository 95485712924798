import {inject, LazyServiceIdentifer} from 'inversify';

import {IServiceFactory} from '@/factories/service-factory';
import type {IApiService} from '@/services/api/IApiService';
import type {
	ConfirmDomainPostData,
	TConfirmDomainDataResponse,
} from '@/services/api/interfaces/confirm-domain';
import type {
	ConfirmRegRuDomainPostData,
	TConfirmRegRuDomainDataResponse,
} from '@/services/api/interfaces/confirm-regru-domain';
import type {TGetDomainsResponse} from '@/services/api/interfaces/domains';
import type {TGetDomainsDataResponse} from '@/services/api/interfaces/get-domains';
import type {TGetMeResponse} from '@/services/api/interfaces/me';
import {IHttpAdapterService} from '@/services/http-adapter/IHttpAdapterService';
import type {IHttpAdapterResponse} from '@/services/http-adapter/interfaces';
import {ServiceBase} from '@/services/ServiceBase';

import type {
	AddDomainPostData,
	AddPartnersDomainPostData,
	TAddDomainDataResponse,
} from './interfaces/add-domain';
import type {TCheckConfirmDomainDataResponse} from './interfaces/check-confirm-domain';
import type {TGetPSRegStatusResponse} from './interfaces/ps-reg-status';
import type {
	RequestFeedbackPostData,
	RequestPostData,
	RequestRespondentsPostData,
	TRequestResponse,
} from './interfaces/request';
import type {TVerifyRecaptchaResponse, VerifyRecaptchaPostData} from './interfaces/verify-recaptcha';

// note: надо быть аккуратнее с урлами, некоторые должны иметь на конце слэш, а какие-то нет

export class ApiService extends ServiceBase implements IApiService {
	protected httpAdapterService: IHttpAdapterService;
	constructor(
		@inject(new LazyServiceIdentifer(() => IServiceFactory)) protected serviceFor: IServiceFactory,
	) {
		super();
		this.httpAdapterService = this.serviceFor(IHttpAdapterService);
	}

	public async request<TD extends RequestPostData, TR = TRequestResponse>(
		data: TD,
	): Promise<IHttpAdapterResponse<TR>> {
		return await this.httpAdapterService.post<TD, TR>('/api/requests/', data);
	}

	public async requestFeedback<TD extends RequestFeedbackPostData, TR = TRequestResponse>(
		data: TD,
	): Promise<IHttpAdapterResponse<TR>> {
		return await this.httpAdapterService.post<TD, TR>('/landing/feedback/', data);
	}

	public async requestRespondents<TD extends RequestRespondentsPostData, TR = TRequestResponse>(
		data: TD,
	): Promise<IHttpAdapterResponse<TR>> {
		const headers = {
			'MRG-BIZ-CRM-To': 'respondents-request',
		};
		return await this.httpAdapterService.post<TD, TR>('/api/requests/', data, {headers});
	}

	public async getDomains<TR = TGetDomainsDataResponse>(): Promise<IHttpAdapterResponse<TR>> {
		return await this.httpAdapterService.get<TR>('/api/domains');
	}

	public async verifyRecaptcha<TD extends VerifyRecaptchaPostData, TR = TVerifyRecaptchaResponse>(
		data: TD,
	): Promise<IHttpAdapterResponse<TR>> {
		return await this.httpAdapterService.post<TD, TR>('/api/verify-recaptcha/', data);
	}

	public async addOwnDomain<TD extends AddDomainPostData, TR = TAddDomainDataResponse>(
		data: TD,
	): Promise<IHttpAdapterResponse<TR>> {
		return await this.httpAdapterService.post<TD, TR>('/api/domains', data);
	}

	public async addBizDomain<TD extends AddDomainPostData, TR = TAddDomainDataResponse>(
		data: TD,
	): Promise<IHttpAdapterResponse<TR>> {
		return await this.httpAdapterService.post<TD, TR>('/api/domains/selfhost', data);
	}

	public async addPartnersDomain<TD extends AddPartnersDomainPostData, TR = TAddDomainDataResponse>(
		data: TD,
	): Promise<IHttpAdapterResponse<TR>> {
		return await this.httpAdapterService.post<TD, TR>('/partner/domains/create/', data);
	}

	public async getSoonDeletedDomains<TR = TGetDomainsResponse>(): Promise<IHttpAdapterResponse<TR>> {
		return await this.httpAdapterService.get<TR>('/api/domains?show_deleted=true');
	}

	public async getMe<TR = TGetMeResponse>(): Promise<IHttpAdapterResponse<TR>> {
		return await this.httpAdapterService.get<TR>('/api/me');
	}

	public async getPSRegistrationStatus<TR = TGetPSRegStatusResponse>(
		linkUID: string,
	): Promise<IHttpAdapterResponse<TR>> {
		return await this.httpAdapterService.get<TR>(`/partner/registration/${linkUID}/`);
	}

	public async confirmDomain<TD extends ConfirmDomainPostData, TR = TConfirmDomainDataResponse>(
		domainId: string,
		data: TD,
	): Promise<IHttpAdapterResponse<TR>> {
		const url = `/api/domains/${domainId}/confirm`;

		return await this.httpAdapterService.post<TD, TR>(url, data);
	}

	public async checkConfirmDomain<TR = TCheckConfirmDomainDataResponse>(
		domainId: string,
		taskId: string,
	): Promise<IHttpAdapterResponse<TR>> {
		const url = `/api/domains/${domainId}/confirm/${taskId}`;

		return await this.httpAdapterService.get<TR>(url);
	}

	public async confirmRegRuDomain<
		TD extends ConfirmRegRuDomainPostData,
		TR = TConfirmRegRuDomainDataResponse,
	>(domainId: string, data: TD): Promise<IHttpAdapterResponse<TR>> {
		const url = `/api/domains/${domainId}/regru`;

		return await this.httpAdapterService.post<TD, TR>(url, data);
	}
}
