type ResolveIdAndNameResult = {
	id: string;
	name: string;
};

export function resolveIdAndName(id: string, name: string): ResolveIdAndNameResult {
	return {
		id,
		name: name ? name : id,
	};
}
