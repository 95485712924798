import classNames from 'classnames';
import React, {PropsWithChildren, Ref, useCallback} from 'react';

import {Link, LinkProps} from '../link/Link';
import styles from './Button.module.css';
import {NativeButton, NativeButtonProps} from './NativeButton';

export enum BUTTON_STYLES {
	PRIMARY = 'primary',
	CONTRAST = 'contrast',
	GREY = 'grey',
	LIGHT = 'light',
	OUTLINE = 'outline',
	DARK = 'dark',
}

interface ButtonProps extends Omit<NativeButtonProps, 'onClick'>, Omit<LinkProps, 'onClick' | 'href'> {
	href?: string;

	as?: 'button' | 'a' | 'nativeLink';
	style?: BUTTON_STYLES;

	fullWidth?: boolean;

	buttonRef?: Ref<HTMLAnchorElement> & Ref<HTMLButtonElement>;

	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
	onClick?: (event, value) => void;
}

/**
 * Primary UI component for user interaction
 */
// eslint-disable-next-line max-lines-per-function
export const Button = ({
	onClick,
	as = 'button',
	href,
	target,
	style = BUTTON_STYLES.PRIMARY,
	fullWidth = false,
	id,
	name,
	value,
	className,
	type,
	tabIndex,
	disabled,
	'aria-label': ariaLabel,
	'aria-haspopup': ariaHaspopup,
	'aria-controls': ariaControls,
	'aria-expanded': ariaExpanded,
	title,
	buttonRef,
	children,
	onMouseEnter,
	onMouseLeave,
}: PropsWithChildren<ButtonProps>) => {
	const handleClick = useCallback(
		(event) => {
			onClick?.(event, value);
		},
		[onClick, value],
	);

	const fullClassName = classNames(className, styles.root, {
		[styles.stateFullWidth]: fullWidth === true,
		[styles.appearanceContrast]: style === BUTTON_STYLES.CONTRAST,
		[styles.appearanceLight]: style === BUTTON_STYLES.LIGHT,
		[styles.appearanceOutline]: style === BUTTON_STYLES.OUTLINE,
		[styles.appearanceDark]: style === BUTTON_STYLES.DARK,
		[styles.appearanceGrey]: style === BUTTON_STYLES.GREY,
		[styles.stateDisabled]: disabled,
	});

	if (as === 'button') {
		return (
			<NativeButton
				id={id}
				onClick={handleClick}
				tabIndex={tabIndex}
				className={fullClassName}
				aria-label={ariaLabel}
				aria-haspopup={ariaHaspopup}
				aria-controls={ariaControls}
				aria-expanded={ariaExpanded}
				title={title}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				ref={buttonRef}
				disabled={disabled}
				type={type}
				name={name}
				value={value}
			>
				{children}
			</NativeButton>
		);
	}

	if (as === 'a') {
		return (
			<Link
				id={id}
				onClick={handleClick}
				className={fullClassName}
				aria-label={ariaLabel}
				title={title}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				href={href}
				target={target}
				ref={buttonRef}
			>
				{children}
			</Link>
		);
	}

	if (as === 'nativeLink') {
		return (
			<a
				id={id}
				onClick={handleClick}
				tabIndex={tabIndex}
				className={fullClassName}
				aria-label={ariaLabel}
				aria-haspopup={ariaHaspopup}
				aria-controls={ariaControls}
				aria-expanded={ariaExpanded}
				title={title}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				href={href}
				target={target}
				ref={buttonRef}
			>
				{children}
			</a>
		);
	}

	return null;
};
