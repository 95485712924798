import {action, computed, observable} from 'mobx';
import React from 'react';

export class AppRootModel {
	public servicesElementsMap: Map<string, any> = observable(new Map(), {deep: false});

	@action
	public renderServiceElement<T>(elementIdentifier: string, element: T) {
		this.servicesElementsMap.set(elementIdentifier, element);
	}

	@action
	public unmountServiceElement(elementIdentifier: string) {
		this.servicesElementsMap.delete(elementIdentifier);
	}

	@action
	public unmountAllElements() {
		this.servicesElementsMap.clear();
	}

	@computed
	get servicesElements() {
		const elements = Array.from(this.servicesElementsMap.values());
		return elements.reduceRight<React.ReactElement[]>((acc, current) => {
			acc.push(current);
			return acc;
		}, []);
	}

	getServiceElement(elementIdentifier: string) {
		return this.servicesElementsMap.get(elementIdentifier);
	}
}
