import type {
	ConfirmDomainPostData,
	TConfirmDomainDataResponse,
} from '@/services/api/interfaces/confirm-domain';
import type {TGetDomainsResponse} from '@/services/api/interfaces/domains';
import type {TGetDomainsDataResponse} from '@/services/api/interfaces/get-domains';
import type {TGetMeResponse} from '@/services/api/interfaces/me';
import type {
	RequestFeedbackPostData,
	RequestPostData,
	RequestRespondentsPostData,
	TRequestResponse,
} from '@/services/api/interfaces/request';
import type {IHttpAdapterResponse} from '@/services/http-adapter/interfaces';

import type {
	AddDomainPostData,
	AddPartnersDomainPostData,
	TAddDomainDataResponse,
} from './interfaces/add-domain';
import type {TCheckConfirmDomainDataResponse} from './interfaces/check-confirm-domain';
import type {
	ConfirmRegRuDomainPostData,
	TConfirmRegRuDomainDataResponse,
} from './interfaces/confirm-regru-domain';
import type {TGetPSRegStatusResponse} from './interfaces/ps-reg-status';
import type {TVerifyRecaptchaResponse, VerifyRecaptchaPostData} from './interfaces/verify-recaptcha';

export abstract class IApiService {
	public abstract request<TD extends RequestPostData, TR = TRequestResponse>(
		data: TD,
	): Promise<IHttpAdapterResponse<TR>>;

	public abstract requestFeedback<TD extends RequestFeedbackPostData, TR = TRequestResponse>(
		data: TD,
	): Promise<IHttpAdapterResponse<TR>>;

	public abstract requestRespondents<TD extends RequestRespondentsPostData, TR = TRequestResponse>(
		data: TD,
	): Promise<IHttpAdapterResponse<TR>>;

	public abstract getDomains<TR = TGetDomainsDataResponse>(): Promise<IHttpAdapterResponse<TR>>;

	public abstract verifyRecaptcha<TD extends VerifyRecaptchaPostData, TR = TVerifyRecaptchaResponse>(
		data: TD,
	): Promise<IHttpAdapterResponse<TR>>;

	public abstract addOwnDomain<TD extends AddDomainPostData, TR = TAddDomainDataResponse>(
		data: TD,
	): Promise<IHttpAdapterResponse<TR>>;

	public abstract addBizDomain<TD extends AddDomainPostData, TR = TAddDomainDataResponse>(
		data: TD,
	): Promise<IHttpAdapterResponse<TR>>;

	public abstract addPartnersDomain<TD extends AddPartnersDomainPostData, TR = TAddDomainDataResponse>(
		data: TD,
	): Promise<IHttpAdapterResponse<TR>>;

	public abstract getSoonDeletedDomains<TR = TGetDomainsResponse>(): Promise<IHttpAdapterResponse<TR>>;

	public abstract getMe<TR = TGetMeResponse>(): Promise<IHttpAdapterResponse<TR>>;

	public abstract getPSRegistrationStatus<TR = TGetPSRegStatusResponse>(
		linkUID: string,
	): Promise<IHttpAdapterResponse<TR>>;

	public abstract confirmDomain<TD extends ConfirmDomainPostData, TR = TConfirmDomainDataResponse>(
		domainId: string,
		data: TD,
	): Promise<IHttpAdapterResponse<TR>>;

	public abstract checkConfirmDomain<TR = TCheckConfirmDomainDataResponse>(
		domainId: string,
		taskId: string,
	): Promise<IHttpAdapterResponse<TR>>;

	public abstract confirmRegRuDomain<
		TD extends ConfirmRegRuDomainPostData,
		TR = TConfirmRegRuDomainDataResponse,
	>(domainId: string, data: TD): Promise<IHttpAdapterResponse<TR>>;
}
