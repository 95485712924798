import {Dialog} from '@/components/base/dialog/Dialog';
import {AddPhone} from '@/components/common/forms/add-phone/AddPhone';
import CreateProjectForm from '@/components/common/forms/create-project-form/CreateProjectForm';
import {getAvatarUrl, UserInfo} from '@/components/common/user-info/UserInfo';
import {injectProperty} from '@/container/injections';
import {activeUser} from '@/hooks/use-get-active-user';
import {MeStore} from '@/mobx/business-models/me/MeModel';
import {IApiService} from '@/services/api/IApiService';
import {Feature} from '@/services/api/interfaces/me';
import {IFeatureService} from '@/services/feature/IFeatureService';
import {ILightboxService} from '@/services/lightbox/ILightboxService';
import {ReadonlyLightbox} from '@/services/lightbox/interfaces';
import {XrayEventId} from '@/services/metrics/constants';
import {IMetricsService} from '@/services/metrics/IMetricsService';
import {showChangeAuthForm} from '@/utilites/auth/auth-form';
import {createAddPhoneActions} from '@/utilites/createProjectDialogHelpers/addPhoneActions';
import {clearCreateProjectDialogOpened} from '@/utilites/createProjectDialogHelpers/clearCreateProjectDialogOpened';
import {setCreateProjectDialogOpened} from '@/utilites/createProjectDialogHelpers/setCreateProjectDialogOpened';

export class CreateProjectDialog extends ReadonlyLightbox {
	@injectProperty(IApiService) protected apiService!: IApiService;
	@injectProperty(ILightboxService) protected lightboxService!: ILightboxService;
	@injectProperty(IMetricsService) protected readonly metricsService!: IMetricsService;
	@injectProperty(IFeatureService) protected readonly featureService!: IFeatureService;

	private readonly addPhoneActions = createAddPhoneActions();

	handleValidSubmit = () => this.props.onClose();
	handleClose = () => this.props.onClose();

	onLoginChangeClick = () => {
		setCreateProjectDialogOpened();

		showChangeAuthForm(clearCreateProjectDialogOpened);

		this.metricsService.sendEvent({
			xrayEvents: [
				{
					name: XrayEventId.clickChangeAuth,
				},
			],
		});
	};

	componentWillUnmount() {
		this.addPhoneActions.unsubscribeFromAddDone();
	}

	handleAddPhoneClick = () => {
		this.addPhoneActions.openMailPhoneAdd();

		this.metricsService.sendEvent({
			xrayEvents: [
				{
					name: XrayEventId.clickAddPhone,
				},
			],
		});
	};

	render() {
		const email = activeUser.email;
		const fullName = activeUser.fullName;
		const avatar = getAvatarUrl(email, 48);
		const {has_verified_phone} = MeStore;
		const isAddPhoneRequired =
			!has_verified_phone &&
			this.featureService.checkIfFeatureExist(Feature.PhoneRequiredForDomainCreate);

		return (
			<Dialog.Body>
				{email && fullName && (
					<UserInfo
						email={email}
						avatar={avatar}
						fullName={fullName}
						onClick={this.onLoginChangeClick}
					/>
				)}
				{isAddPhoneRequired ? (
					<AddPhone onAddClick={this.handleAddPhoneClick} />
				) : (
					<CreateProjectForm
						onValidSubmit={this.handleValidSubmit}
						onClose={this.handleClose}
						onLoginChangeClick={this.onLoginChangeClick}
					/>
				)}
			</Dialog.Body>
		);
	}
}
