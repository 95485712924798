import type {interfaces} from 'inversify';

import {getGuid} from '@/utilites/get-guid';

export type THashGeneratorTarget = interfaces.Newable<any> | interfaces.Abstract<any>;

export class ClassHashGenerator {
	private static readonly map = new WeakMap<THashGeneratorTarget, string>();

	public static getHash(target?: THashGeneratorTarget) {
		if (!target) {
			return 'undefined';
		}

		let hash = this.map.get(target);
		if (!hash) {
			hash = getGuid();
			this.map.set(target, hash);
		}

		return hash;
	}
}
