import {autorun} from 'mobx';
import {useRouter} from 'next/router';
import {useEffect} from 'react';

import {ProjectHrefs, QUERY_PARAM_IDS} from '@/constants';
import {PSRegStatusStore} from '@/mobx/business-models/ps-registration-status/PSRegStatusModel';
import {showChangeAuthForm} from '@/utilites/auth/auth-form';

import {useAuthButtonActions} from './use-auth-button-actions';
import {useGetQueryParam} from './use-get-query-param';

export function usePartnersApiFlow() {
	const {isAuth, handleOpenCreateProjectDialog} = useAuthButtonActions();
	const reqTokenParam = useGetQueryParam(QUERY_PARAM_IDS.REG_TOKEN);
	const router = useRouter();
	const isProhibitedPage = router.asPath.includes(ProjectHrefs.verify);

	useEffect(
		() =>
			autorun(async () => {
				if (!reqTokenParam) {
					return;
				}
				if (!isAuth) {
					showChangeAuthForm();
					return;
				}
				if (!PSRegStatusStore.isLoaded) {
					await PSRegStatusStore.load(reqTokenParam);
					if (!isProhibitedPage) {
						handleOpenCreateProjectDialog();
					}
				}
			}),
		[handleOpenCreateProjectDialog, isAuth, isProhibitedPage, reqTokenParam],
	);
}
