import {makeObservable, observable} from 'mobx';

type DeepPartial<T> = {
	[P in keyof T]?: T[P] extends Array<infer U> ? Array<DeepPartial<U>> : DeepPartial<T[P]>;
};
type BaseConstructorPayload<T, U = undefined> = DeepPartial<U extends undefined ? T : T | U>;

type Constructor<T = any> = {new (...args: any[]): T};

type Indexable = {[key: string]: any};

const CONSTRUCTOR_META = Symbol('CONSTRUCTOR_META');

export function Model<T extends Constructor>(constructor: T) {
	return class extends constructor {
		constructor(...args: any[]) {
			super();
			this.init(args[0]);
			Object.setPrototypeOf(this, constructor.prototype);
		}
	};
}

export class BaseModel<T, U = undefined> {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(_payload: BaseConstructorPayload<T, U>) {}

	protected init(payload: any) {
		for (const key in payload) {
			if (this.hasOwnProperty(key)) {
				const designType: Constructor = Reflect.getMetadata('design:type', this, key);
				const constructorMeta: Constructor = Reflect.getMetadata(CONSTRUCTOR_META, this, key);
				const factory = constructorMeta || designType;

				const isArray = designType === Array;
				const value = isArray
					? payload[key].map((v) => this.parseValue(v, factory))
					: this.parseValue(payload[key], factory);
				(this as Indexable)[key] = value;
				if (value) {
					observable(this, key);
				}
			}
		}
		makeObservable(this);
	}

	// todo: добавить парсинг даты и др. специфичных типов
	private parseValue(value: any, Factory: Constructor) {
		if (Factory && Factory.prototype instanceof BaseModel.constructor) {
			return new Factory(value);
		}
		return value;
	}
}
