import {PropsWithChildren} from 'react';

import {Typography, VARIANTS} from '@/components/base/typography/Typography';

import styles from './DialogTitle.module.css';

export function DialogTitle({children}: PropsWithChildren<any>) {
	return (
		<Typography variant={VARIANTS.h4} className={styles.title}>
			{children}
		</Typography>
	);
}

DialogTitle.displayName = 'DialogTitle';
