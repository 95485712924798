import features from '@mail/features';

type Features = {
	/* BIZSAAS-399 Изменение текста кнопки создания личного кабинета */
	CREATE_LK_BUTTON_TEXT: string;
	/* https://jira.vk.team/browse/BIZSAAS-491 Пользователь из снг или нет */
	IS_SNG_USER: boolean;
	IS_FIRST_PARTNERS: boolean;
};

export const FEATURES: Features = {
	CREATE_LK_BUTTON_TEXT: features.getAsString('create-lk-button-text', 'Создать личный кабинет'),
	IS_SNG_USER: features.has('is-sng'),
	IS_FIRST_PARTNERS: features.has('is-first-partners'),
};
