import 'reflect-metadata';

import {Container} from 'inversify';

import {IServiceFactory, serviceFactory} from '@/factories/service-factory';
import {ApiService} from '@/services/api/ApiService';
import {IApiService} from '@/services/api/IApiService';
import {ConfigService} from '@/services/config/ConfigService';
import {IConfigService} from '@/services/config/IConfigService';
import {CookieService} from '@/services/cookie/CookieService';
import {ICookieService} from '@/services/cookie/ICookieService';
import {FeatureService} from '@/services/feature/FeatureService';
import {IFeatureService} from '@/services/feature/IFeatureService';
import {HttpAdapterService} from '@/services/http-adapter/HttpAdapterService';
import {IHttpAdapterService} from '@/services/http-adapter/IHttpAdapterService';
import {ILightboxService} from '@/services/lightbox/ILightboxService';
import {LightboxService} from '@/services/lightbox/LightboxService';
import {IMetricsService} from '@/services/metrics/IMetricsService';
import {MetricsService} from '@/services/metrics/MetricsService';
import {IPortalRendererService} from '@/services/portal-render/IPortalRendererService';
import {PortalRendererService} from '@/services/portal-render/PortalRenderService';
import {IRecaptchaService} from '@/services/recaptcha/IRecaptchaService';
import {RecaptchaService} from '@/services/recaptcha/RecaptchaService';

const APPLICATION_CONTAINER = new Container();

APPLICATION_CONTAINER.bind(IServiceFactory).toFactory(serviceFactory);
APPLICATION_CONTAINER.bind<IPortalRendererService>(IPortalRendererService)
	.to(PortalRendererService)
	.inSingletonScope();
APPLICATION_CONTAINER.bind<ILightboxService>(ILightboxService).to(LightboxService).inSingletonScope();
APPLICATION_CONTAINER.bind<IHttpAdapterService>(IHttpAdapterService)
	.to(HttpAdapterService)
	.inSingletonScope();
APPLICATION_CONTAINER.bind<IApiService>(IApiService).to(ApiService).inSingletonScope();
APPLICATION_CONTAINER.bind<IFeatureService>(IFeatureService).to(FeatureService).inSingletonScope();
APPLICATION_CONTAINER.bind<ICookieService>(ICookieService).to(CookieService).inSingletonScope();
APPLICATION_CONTAINER.bind<IMetricsService>(IMetricsService).to(MetricsService).inSingletonScope();
APPLICATION_CONTAINER.bind<IConfigService>(IConfigService).to(ConfigService).inSingletonScope();
APPLICATION_CONTAINER.bind<IRecaptchaService>(IRecaptchaService).to(RecaptchaService).inSingletonScope();

export default APPLICATION_CONTAINER;
