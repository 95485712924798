import {getEventMatcher} from './get-event-matcher';
import {ESCAPE} from './keys-map';

const matcher = getEventMatcher(['Escape', 'Esc'], ESCAPE);

/**
 * @param {KeyboardEvent} event
 * @returns {boolean}
 */
export function isEscape(event: KeyboardEvent) {
	return matcher(event);
}
