import '@/styles/tokens.css';
import '@/styles/theme-variables.css';
import '@/styles/globals.css';
import '@/fonts/fonts.css';
import '../../inversify.config';
import '../features';

import {Observer} from 'mobx-react';
import type {AppProps} from 'next/app';
import {Fragment, useEffect} from 'react';

import type {ProjectHrefs} from '@/constants';
import {useAppRootModel} from '@/hooks/use-app-root-model';
import {useActiveUser} from '@/hooks/use-get-active-user';
import {useInjection} from '@/hooks/use-injection';
import {usePartnersApiFlow} from '@/hooks/use-partners-api-flow';
import {useUserDataMetricsSend} from '@/hooks/use-user-data-metrics-send';
import {IConfigService} from '@/services/config/IConfigService';
import {IPortalRendererService} from '@/services/portal-render/IPortalRendererService';
import {mirageServer} from '@/utilites/mirage';

if (process.env.NODE_ENV === 'development') {
	mirageServer({environment: 'development'});
}

function MyApp({Component, pageProps, router}: AppProps) {
	const [appRootModelInstance] = useAppRootModel();
	const [portalRenderService] = useInjection(IPortalRendererService);
	const [configService] = useInjection(IConfigService);

	configService.page = router.route as ProjectHrefs;

	useEffect(() => {
		portalRenderService.registerRootModel(appRootModelInstance);
		return () => portalRenderService.unmountAllElements();
	}, [appRootModelInstance, portalRenderService, configService]);

	useActiveUser();
	useUserDataMetricsSend();
	usePartnersApiFlow();

	return (
		<>
			<Component {...pageProps} />
			<Observer>
				{() => <Fragment key={'services-elements'}>{appRootModelInstance.servicesElements}</Fragment>}
			</Observer>
		</>
	);
}

export default MyApp;
