import {setCreateProjectDialogOpened} from './setCreateProjectDialogOpened';

export const createAddPhoneActions = () => {
	let contactsWin: Window | null = null;

	const reloadPageOnPhoneAdd = () => {
		if (document.hidden) {
			return;
		}
		setCreateProjectDialogOpened();
		contactsWin.close();
		window.location.reload();
	};

	/**
	 * Отписывается от проверки возврата на страницу
	 */
	const unsubscribeFromAddDone = () => {
		document.removeEventListener('visibilitychange', reloadPageOnPhoneAdd);
	};

	/**
	 * Открывает id.mail.ru с добавлением телефона
	 *
	 * На анмаунт вызывайте `unsubscribeFromAddDone`
	 */
	const openMailPhoneAdd = () => {
		unsubscribeFromAddDone();
		contactsWin = window.open('https://id.mail.ru/contacts?open-add-phone=1', '_blank');
		document.addEventListener('visibilitychange', reloadPageOnPhoneAdd);
	};

	return {
		unsubscribeFromAddDone,
		openMailPhoneAdd,
	};
};
