import xray from '@mail/xray';
import {inject} from 'inversify';

import {getPythonSettings} from '@/components/helpers/get-python-settings';
import {IConfigService} from '@/services/config/IConfigService';
import {
	type GA4EventId,
	type TMRReachGoalId,
	type YAReachGoalId,
	TMREventType,
} from '@/services/metrics/constants';
import type {IMetricsService} from '@/services/metrics/IMetricsService';
import type {SendEventParams, UAEvent, XrayEvent} from '@/services/metrics/interfaces';
import {ServiceBase} from '@/services/ServiceBase';

import {getUAParams} from './utils/get-ua-params';
import {getUtmMetricsFromUrl} from './utils/getUtmMetricsFromUrl';

export class MetricsService extends ServiceBase implements IMetricsService {
	constructor(@inject(IConfigService) protected configService: IConfigService) {
		super();
	}

	public sendEvent({yaGoals, tmrGoals, uaEvents, ga4Events, xrayEvents}: SendEventParams) {
		if (yaGoals && yaGoals.length > 0) {
			this.sendYAEvents(yaGoals);
		}

		if (tmrGoals && tmrGoals.length > 0) {
			this.sendTMREvents(tmrGoals);
		}

		if (uaEvents && uaEvents.length > 0) {
			this.sendUAEvents(uaEvents);
		}

		if (ga4Events && ga4Events.length > 0) {
			this.sendGA4Events(ga4Events);
		}

		if (xrayEvents && xrayEvents.length > 0) {
			this.sendXrayEvents(xrayEvents);
		}
	}

	public sendTMRPageView() {
		const tmrIds = this.configService.config.TMR_COUNTERS_IDS;
		const {userEmail} = getPythonSettings();

		window?._tmr?.push({
			id: tmrIds.BASE_TMR_COUNTER,
			type: TMREventType.pageView,
			start: new Date().getTime(),
			pid: 'USER_ID',
		});
		window?._tmr?.push({
			id: tmrIds.MAIL_SAAS_TMR_COUNTER,
			type: TMREventType.pageView,
			start: new Date().getTime(),
			userid: userEmail,
		});
	}

	public setupXray() {
		const {userEmail} = getPythonSettings();
		const omicronData = window?.omicronData;
		const isProd = window?.location.host === 'biz.mail.ru';

		xray.setConfig({
			verbose: true,
			defaultParams: {
				p: this.configService.config.XRAY_PROJECT_NAME,
				email: userEmail,
			},
			radarPrefix: isProd ? '' : 'dev',
			utm: getUtmMetricsFromUrl(),
			o_ss: omicronData?.short_segments ?? '',
			o_v: omicronData?.config_v ? String(omicronData.config_v) : '',
		});
	}

	private sendYAEvents(yaGoals: YAReachGoalId[]) {
		const metrikaId = this.configService.config.YA_METRIKA_ID;

		if (metrikaId) {
			yaGoals.forEach((yaGoal) => {
				window?.ym(metrikaId, 'reachGoal', yaGoal);
			});
		} else {
			console.warn(`No YA id was passed. Event: ${JSON.stringify(yaGoals)} was not sent`);
		}
	}

	private sendTMREvents(tmrGoals: TMRReachGoalId[]) {
		const tmrIds = this.configService.config.TMR_COUNTERS_IDS;
		const {userEmail} = getPythonSettings();

		tmrGoals.forEach((tmrGoal) => {
			window?._tmr?.push({id: tmrIds.BASE_TMR_COUNTER, type: TMREventType.reachGoal, goal: tmrGoal});
			window?._tmr?.push({
				id: tmrIds.MAIL_SAAS_TMR_COUNTER,
				type: TMREventType.reachGoal,
				goal: tmrGoal,
				userid: userEmail,
			});
		});
	}

	private sendUAEvents(uaEvents: UAEvent[]) {
		const uaId = this.configService.config.UA_ID;

		if (uaId) {
			uaEvents.forEach(({type, payload}) => {
				window?.gtag('event', type, {
					...getUAParams(payload),
					send_to: uaId,
				});
			});
		} else {
			console.warn(`No UA id was passed. Event: ${JSON.stringify(uaEvents)} was not sent`);
		}
	}

	private sendGA4Events(ga4Events: GA4EventId[]) {
		const ga4Id = this.configService.config.GA4_ID;

		if (ga4Id) {
			ga4Events.forEach((event) => {
				window?.gtag('event', event, {send_to: ga4Id});
			});
		} else {
			console.warn(`No GA4 id was passed. Event: ${JSON.stringify(ga4Events)} was not sent`);
		}
	}

	private sendXrayEvents(xrayEvents: XrayEvent[]) {
		const {isAuth, hasDomain} = getPythonSettings();

		xrayEvents.forEach((event) => {
			const {i, params} = event;
			const {withScrollPosition, ...rest} = params || {};
			xray.send(event.name, {
				i,
				dwh: {
					...rest,
					...(withScrollPosition ? {scrollPos: Math.round(window.scrollY)} : {}),
					source: (this.configService.page ?? '').replaceAll('/', ''),
					isAuth,
					hasDomain,
				},
			});
		});
	}
}
