import qs from 'qs';

import {urlParser} from '@/utilites/url-parser/url-parser';

// todo: biz-10025, запилить нормальный типизированный сервис для PortalHeader'а

export const getBaseUrl = () => {
	return window?.location ? `${location.protocol}//${location.hostname}` : '';
};

export function getBackURLFromPHSettings(__PHS): string | false {
	if (!__PHS) {
		return false;
	}

	// Back URL after registration
	const {registerBackUrl} = __PHS;
	if (registerBackUrl) {
		return registerBackUrl;
	}

	// Login URL for portal header with `Page` param with actual link
	const {loginLink} = __PHS;
	if (loginLink) {
		const {search} = urlParser(loginLink);
		const url = qs.stringify(search);

		return url || false;
	}

	return false;
}

function getDefaultSuccessURL() {
	const backUrl = getBackURLFromPHSettings(window && '__PHS' in window && window.__PHS);

	if (backUrl) {
		return backUrl;
	}

	return getBaseUrl();
}

interface OpenLoginDialogParams {
	titleText?: string;
	infoText?: string;
	successPage?: string;
	lang?: string;
	modal?: boolean;
}

interface PH {
	authForm: {
		show: (params?: OpenLoginDialogParams) => void;
	};
}

export function openLoginDialog(params?: OpenLoginDialogParams): boolean {
	if (window && window.__PH) {
		const portalHeader: PH = window.__PH;
		try {
			const {
				titleText = undefined,
				infoText = undefined,
				successPage = getDefaultSuccessURL(),
				lang = 'ru_RU',
				modal = false,
			} = params ?? {};

			if (!successPage) {
				throw new Error('No successPage provided');
			}

			portalHeader?.authForm?.show?.({
				titleText,
				infoText,
				successPage,
				lang,
				modal,
			});
			return true;
		} catch (error: any) {
			console.error(error);
			return false;
		}
	}

	return false;
}
