import classNames from 'classnames';
import {forwardRef, PropsWithChildren} from 'react';

import styles from './PureDialog.module.css';

const dialogTypeToRole = {
	message: 'dialog',
	alert: 'alertdialog',
};

export interface PureDialogProps {
	open?: boolean;
	type?: 'message' | 'alert';
	className?: string;
	fullScreen?: boolean;
	disableBackdropScroll?: boolean;
	'aria-label'?: string;
	'aria-labelledby'?: string;
}

export const PureDialog = forwardRef<HTMLDivElement, PropsWithChildren<PureDialogProps>>(function PureDialog(
	{
		open = false,
		children,
		type = 'message',
		className,
		fullScreen,
		'aria-label': ariaLabel,
		'aria-labelledby': ariaLabelledBy,
	},
	ref,
) {
	if (open === false) {
		return null;
	}

	const rootClassName = classNames(className, styles.root, {
		[styles.stateFullScreen]: fullScreen === true,
	});

	return (
		<div
			ref={ref}
			className={rootClassName}
			tabIndex={-1}
			role={dialogTypeToRole[type] || 'dialog'}
			aria-label={typeof ariaLabelledBy === 'string' ? null : ariaLabel}
			aria-labelledby={typeof ariaLabel === 'string' ? null : ariaLabelledBy}
		>
			{children}
		</div>
	);
});
