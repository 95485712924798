import {inject, LazyServiceIdentifer} from 'inversify';

import {IServiceFactory} from '@/factories/service-factory';
import {IApiService} from '@/services/api/IApiService';
import {Feature, GetMeSuccessResponse} from '@/services/api/interfaces/me';
import {IFeatureService} from '@/services/feature/IFeatureService';
import {HttpAdapterResponseExtractor} from '@/services/http-adapter/HttpAdapterResponseExtractor';
import {ServiceLiveBase} from '@/services/ServiceLiveBase';
import {isNull} from '@/utilites/type-guards/Primitives';
import {Nullable} from '@/utilites/utility-types';

export class FeatureService extends ServiceLiveBase implements IFeatureService {
	constructor(
		@inject(new LazyServiceIdentifer(() => IServiceFactory)) protected serviceFor: IServiceFactory,
	) {
		super();
	}

	protected featuresSet: Nullable<Set<Feature>> = null;

	protected async onStart() {
		return this.loadFeatures();
	}

	protected onStop() {
		this.featuresSet?.clear();
	}

	protected async loadFeatures(): Promise<void> {
		const apiService = this.serviceFor(IApiService);
		const response = await apiService.getMe();
		const responseExtractor = new HttpAdapterResponseExtractor(response);
		if (responseExtractor.isSuccessResponse) {
			const data = responseExtractor.getResponseData();
			this.featuresSet = new Set(data.features);
		} else {
			const errorMessage = responseExtractor.getErrorMessage();
			const error = new Error(errorMessage);
			console.error(error);
			// todo: #logger, добавить логгер
		}
	}

	public get features(): GetMeSuccessResponse['features'] {
		return Array.from(this.featuresSet);
	}

	public checkIfFeatureExist(feature: Feature): boolean {
		if (isNull(this.featuresSet)) {
			throw new Error('Trying to execute checkIfFeatureExist() before loadFeatures()');
		}
		return this.featuresSet.has(feature);
	}
}
