import {devPythonSettings} from '@/components/common/python-settings/PythonSettings';

const isDev = (process.env.APP_ENV || process.env.NODE_ENV) === 'development';

const defaultPythonSettings: PythonSettings = isDev
	? devPythonSettings
	: {
			isAuth: false,
			hasDomain: false,
			linkToAdmin: '/',
			linkToAdminAction: '',
			linkToAdminText: '',
			linkToAdminTooltip: '',
			domainName: '',
			domainId: '',

			verifyParams: {
				htmlConfirmationFile: '',
				htmlConfirmationFileUrl: '',
				htmlConfirmationFileName: '',
				dnsConfirmationDnsRecord: '',
				metaConfirmationTag: '',
				nssRecords: [],
				registrarName: '',
				registrarUrl: '',
				hasRegRuTab: null,
				hasNssTab: false,
			},
	  };

export function getPythonSettings(): PythonSettings {
	return typeof window !== 'undefined' && window && window.pythonSettings
		? window.pythonSettings
		: defaultPythonSettings;
}
