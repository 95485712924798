import classNames from 'classnames';
import {ElementType, forwardRef, HTMLAttributes, PropsWithChildren} from 'react';

import styles from './Typography.module.css';

export enum VARIANTS {
	h1 = 'h1',
	h2 = 'h2',
	h3 = 'h3',
	h4 = 'h4',
	text = 'text',
	text15 = 'text15',
	action = 'action',
	accentText = 'accent-text',
	list = 'list',
	listMini = 'list-mini',
	listMaxi = 'list-maxi',
	subLine = 'subLine',
}

type VariantMapType = {
	[key: string]: ElementType;
};

const defaultVariantMap: VariantMapType = {
	[VARIANTS.h1]: 'h1',
	[VARIANTS.h2]: 'h2',
	[VARIANTS.h3]: 'h3',
	[VARIANTS.h4]: 'h4',
	[VARIANTS.text]: 'p',
	[VARIANTS.text15]: 'p',
	[VARIANTS.action]: 'p',
	[VARIANTS.accentText]: 'p',
	[VARIANTS.list]: 'p',
	[VARIANTS.listMini]: 'p',
	[VARIANTS.listMaxi]: 'p',
	[VARIANTS.subLine]: 'span',
};

interface TypographyProps extends HTMLAttributes<HTMLElement> {
	variant: VARIANTS;
	as?: (typeof defaultVariantMap)[VARIANTS];
	className?: string;
	id?: string;
}

const Typography = forwardRef(function Typography(
	{children, variant, as, className, id, ...props}: PropsWithChildren<TypographyProps>,
	ref,
) {
	const Component: ElementType = as || defaultVariantMap[variant];

	const rootClassName = classNames(className, {
		[styles.variationH1]: variant === VARIANTS.h1,
		[styles.variationH2]: variant === VARIANTS.h2,
		[styles.variationH3]: variant === VARIANTS.h3,
		[styles.variationH4]: variant === VARIANTS.h4,
		[styles.variationText]: variant === VARIANTS.text,
		[styles.variationText15]: variant === VARIANTS.text15,
		[styles.variationAction]: variant === VARIANTS.action,
		[styles.variationAccentText]: variant === VARIANTS.accentText,
		[styles.variationList]: variant === VARIANTS.list,
		[styles.variationListMini]: variant === VARIANTS.listMini,
		[styles.variationListMaxi]: variant === VARIANTS.listMaxi,
		[styles.variationSubLine]: variant === VARIANTS.subLine,
	});

	return (
		<Component id={id} className={rootClassName} {...props} ref={ref}>
			{children}
		</Component>
	);
});

Typography.displayName = 'Typography';

export {Typography};
