import {COOKIE_ATTRIBUTES} from '../constants';
import {CookieAttributes, CookieValue} from '../interfaces';

export function formCookieValue(key: string, value: CookieValue, options: CookieAttributes) {
	return Object.entries(options).reduce((acc, [optionsKey, optionsValue]) => {
		const isCookieAttribute = COOKIE_ATTRIBUTES.includes(optionsKey);
		const shouldSkipAttribute = typeof optionsValue === 'boolean' && optionsValue === false;

		if (!isCookieAttribute || shouldSkipAttribute) {
			return acc;
		}

		return `${acc}; ${optionsKey}${optionsValue === true ? '' : `=${optionsValue}`}`;
	}, `${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
}
