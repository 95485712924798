import {inject, LazyServiceIdentifer} from 'inversify';
import React, {type ClassicElement, type ReactChildren, type ReactNode} from 'react';

import {isDialog} from '@/components/base/dialog/Dialog';
import {
	type ErrorDialogProps,
	ErrorDialog,
} from '@/services/lightbox/auxiliary-dialogs/error-dialog/ErrorDialog';
import {ClassHashGenerator} from '@/utilites/hash-generator/ClassHashGenerator';
import {Deferred} from '@/utilites/promise/Deferred';
import {isFunction, isUndefined} from '@/utilites/type-guards/Primitives';
import type {Nullable} from '@/utilites/utility-types';

import {IPortalRendererService} from '../portal-render/IPortalRendererService';
import {ServiceBase} from '../ServiceBase';
import {Lightbox} from './components/Lightbox';
import type {ILightboxService} from './ILightboxService';
import type {
	ICommonLightboxOptions,
	IErrorType,
	IInteractiveLightboxClass,
	IInteractiveLightboxPropsIn,
	InteractiveLightboxPure,
	IReadonlyLightboxClass,
	IReadonlyLightboxPropsIn,
	TInteractiveLightboxOnSubmitArgs,
	TInteractiveLightboxPropsIn,
	TReadonlyLightboxPropsIn,
} from './interfaces';

function castToReactElement(children: ReactChildren | ReactNode) {
	return children as unknown as ClassicElement<Record<string, any>>;
}

export class LightboxService extends ServiceBase implements ILightboxService {
	private classToNameMap = new Map();

	constructor(
		@inject(new LazyServiceIdentifer(() => IPortalRendererService))
		private readonly portalRendererService: IPortalRendererService,
	) {
		super();
	}

	public openReadonly<P extends IReadonlyLightboxPropsIn, S>(
		ContentClass: IReadonlyLightboxClass<P, S>,
		contentProps: TReadonlyLightboxPropsIn<P>,
		lightboxOptions?: ICommonLightboxOptions,
	): void {
		const name = this.getLightboxName(ContentClass);
		const onClose = (...args: any[]) => {
			if (isFunction(contentProps.onClose)) {
				contentProps.onClose(...args);
			}

			this.portalRendererService.unmountElement(name);
		};

		const contentPropsFull = Object.assign({}, contentProps, {onClose}, {}) as unknown as P;

		const child = new ContentClass(contentPropsFull).render();
		const needWrapper = !isDialog(castToReactElement(child));

		const lightboxProps = Object.assign({needWrapper}, lightboxOptions, {onClose, key: name});
		const lightbox = React.createElement(Lightbox, lightboxProps, child);
		this.portalRendererService.renderElement(name, lightbox);
	}

	public async openReadonlyAsync<P extends IReadonlyLightboxPropsIn, S>(
		ContentClass: IReadonlyLightboxClass<P, S>,
		contentProps: TReadonlyLightboxPropsIn<P>,
		lightboxOptions?: ICommonLightboxOptions,
	): Promise<void> {
		await this.portalRendererService.waitForRootModel();
		this.openReadonly(ContentClass, contentProps, lightboxOptions);
	}

	public openInteractive<P extends IInteractiveLightboxPropsIn, S, R>(
		ContentClass: IInteractiveLightboxClass<P, S, R>,
		contentProps: TInteractiveLightboxPropsIn<P>,
		lightboxOptions?: ICommonLightboxOptions,
	): Promise<Nullable<R>> {
		const deferred = new Deferred<Nullable<R>>();
		const name = this.getLightboxName(ContentClass);
		let contentInstance: InteractiveLightboxPure<P, S, R> | null;

		const unMountModal = () => {
			this.portalRendererService.unmountElement(name);
			contentInstance = null;
		};
		const onClose = (...args: any[]) => {
			if (isFunction(contentProps.onClose)) {
				contentProps.onClose(...args);
			}
			unMountModal();
		};

		const ref = (component: InteractiveLightboxPure<P, S, R> | null) => {
			contentInstance = component;
		};

		const onSubmit = async (...args: TInteractiveLightboxOnSubmitArgs<P>) => {
			const result = contentInstance ? await contentInstance.getResult(...args) : undefined;
			if (!isUndefined(result)) {
				deferred.resolve(result);
				if (isFunction(contentProps.onSubmit)) {
					await contentProps.onSubmit(...args);
				}
				onClose(...args);
			}
		};

		const onCancel = async (...args: any[]) => {
			deferred.resolve(null);
			if (isFunction(contentProps.onCancel)) {
				await contentProps.onCancel(...args);
			}
			onClose(...args);
		};

		const onError = (reason: Error) => {
			onClose();
			deferred.reject(reason);
		};

		const contentPropsFull = Object.assign({}, contentProps, {
			ref,
			onSubmit,
			onCancel,
			onClose,
			onError,
		}) as unknown as P;

		const contentElement = React.createElement(ContentClass, contentPropsFull);

		const child = new ContentClass(contentPropsFull).render();
		const needWrapper = !isDialog(castToReactElement(child));

		const lightboxProps = Object.assign({needWrapper}, lightboxOptions, {onClose: onCancel, key: name});
		const lightbox = React.createElement(Lightbox, lightboxProps, contentElement);

		this.portalRendererService.renderElement(name, lightbox);

		return deferred.promise;
	}

	public openError = (
		error?: IErrorType,
		contentProps?: ErrorDialogProps,
		lightboxOptions?: ICommonLightboxOptions,
	) => {
		const errorLightboxProps = Object.assign(
			{text: React.isValidElement(error) ? error : error instanceof Error ? error.message : error},
			contentProps,
			{
				onClose: this.closeError,
			},
		) as ErrorDialogProps;

		this.openReadonly(ErrorDialog, errorLightboxProps, lightboxOptions);
	};

	public closeError = () => {
		const errorLightboxName = this.getLightboxName(ErrorDialog);
		this.portalRendererService.unmountElement(errorLightboxName);
	};

	private getLightboxName(lightboxClass: React.ComponentType<any>): string {
		let name = this.classToNameMap.get(lightboxClass);

		if (!name) {
			name = `Lightbox@${lightboxClass.displayName || ClassHashGenerator.getHash(lightboxClass)}`;
			this.classToNameMap.set(lightboxClass, name);
		}

		return name;
	}
}
