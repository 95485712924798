import {useEffect} from 'react';

import {FEATURES} from '@/feature-flags';
import {useAuthButtonActions} from '@/hooks/use-auth-button-actions';
import {GA4EventId, XrayEventId, YAReachGoalId} from '@/services/metrics/constants';

export function useUserDataMetricsSend() {
	const {hasDomain, isAuth, metricsService} = useAuthButtonActions();

	useEffect(() => {
		metricsService.setupXray();
		metricsService.sendEvent({
			xrayEvents: [
				{
					name: XrayEventId.showMainBiz,
					params: {
						is_sng: FEATURES.IS_SNG_USER,
					},
				},
			],
		});

		metricsService.sendTMRPageView();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (hasDomain && isAuth) {
			metricsService.sendEvent({
				yaGoals: [YAReachGoalId.PanelUpravlenijaView],
				ga4Events: [GA4EventId.PanelUpravlenijaView],
			});
		}
		if (!hasDomain && isAuth) {
			metricsService.sendEvent({
				yaGoals: [YAReachGoalId.PanelUpravlenijaNoDomenView],
				ga4Events: [GA4EventId.PanelUpravlenijaNoDomenView],
			});
		}
		if (!isAuth) {
			metricsService.sendEvent({
				yaGoals: [YAReachGoalId.RegistrationView],
				ga4Events: [GA4EventId.RegistrationView],
			});
		}
	}, []);
}
