import React, {ReactNode} from 'react';

import {Dialog} from '@/components/base/dialog/Dialog';
import styles from '@/components/common/dialogs/success-dialog/SuccessDialog.module.css';
import {ErrorLightbox} from '@/services/lightbox/interfaces';

export interface ErrorDialogProps {
	title?: ReactNode;
	text?: ReactNode;
}

export class ErrorDialog extends ErrorLightbox<ErrorDialogProps> {
	render() {
		const {title, text, onClose, backdrop, disableBackdropScroll, disableBackdropClick} = this.props;
		return (
			<Dialog
				open
				onClose={onClose}
				backdrop={backdrop ?? false}
				disableBackdropScroll={disableBackdropScroll ?? false}
				disableBackdropClick={disableBackdropClick ?? false}
				className={styles.root}
			>
				<Dialog.Body>
					<Dialog.Title>{title || 'Произошла ошибка'}</Dialog.Title>
					<Dialog.Description>
						{text || 'Мы уже знаем о проблеме, скоро исправим'}
					</Dialog.Description>
				</Dialog.Body>
			</Dialog>
		);
	}
}
