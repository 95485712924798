import {FC} from 'react';

import Image from '@/components/common/image/Image';

import ChevronDownSmall from './icons/chevron_down_small_24.svg';
import styles from './UserInfo.module.css';

interface UserInfoProps {
	email: string;
	avatar: string;
	fullName: string;
	onClick: () => void;
}

export const getAvatarUrl = (email: string, size: number, host = 'filin.mail.ru') =>
	`https://${host}/pic?email=${email}&width=${size * 2}px&height=${size * 2}px`;

export const UserInfo: FC<UserInfoProps> = ({email, avatar, fullName, onClick}) => {
	return (
		<div className={styles.container}>
			<div className={styles.avatar}>
				<Image src={avatar} width={48} height={48} alt="Пользователь" objectFit="contain" />
			</div>
			<div className={styles.info}>
				<div className={styles.name}>
					<span className={styles.nameText}>{fullName}</span>
					<ChevronDownSmall onClick={onClick} />
				</div>
				<span className={styles.email}>{email}</span>
			</div>
		</div>
	);
};
