/** Открывает форму релогина через порталку */
export function showChangeAuthForm(onHide?: () => void) {
	onceOnChangeAuthFormHide(onHide);

	window.__PH?.authForm?.show({
		formType: 'relogin',
		mode: 'simple',
		type: 'choice',
		action: 'choice',
		choice_for_relogin: '1',
	});
}

/** Подписаться на закрытие формы авторизации. Обработчик выполнится 1 раз */
export function onceOnChangeAuthFormHide(handler: () => void) {
	window.__PH?.authForm?.once('hide', handler);
}
