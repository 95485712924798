/**
 * @param {string|string[]} keys    List or single key, {@see https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values}
 * @param {number}          keyCode Key code, {@see https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode}
 * @returns {function(KeyboardEvent): boolean}
 */
export function getEventMatcher(keys: string | string[], keyCode: number) {
	const eventKeys = [].concat(keys);

	return function (/* KeyboardEvent */ event) {
		return eventKeys.includes(event.key) || event.keyCode === keyCode;
	};
}
