import {RECAPTCHA_SITE_KEY} from '@/components/common/analytics/recaptcha/Recaptcha';
import {IRecaptchaService} from '@/services/recaptcha/IRecaptchaService';
import {ServiceBase} from '@/services/ServiceBase';

const DEFAULT_TOKEN_VALUE = '';

export class RecaptchaService extends ServiceBase implements IRecaptchaService {
	private get isStarted() {
		return typeof window !== 'undefined' && window && 'grecaptcha' in window;
	}

	private async execute(action: string): Promise<string> {
		if (!this.isStarted) {
			return DEFAULT_TOKEN_VALUE;
		}

		try {
			return (await window.grecaptcha.execute(RECAPTCHA_SITE_KEY, {action})) || DEFAULT_TOKEN_VALUE;
		} catch {
			return DEFAULT_TOKEN_VALUE;
		}
	}

	public getSubmitToken(): Promise<string> {
		return this.execute('submit');
	}
}
