import {BaseCollection} from '@/mobx/business-collections/BaseCollection';
import {DomainModel} from '@/mobx/business-models/domain/DomainModel';
import {GetDomainsSuccessResponse} from '@/services/api/interfaces/domains';
import {HttpAdapterResponseExtractor} from '@/services/http-adapter/HttpAdapterResponseExtractor';
import {IHttpAdapterResponse} from '@/services/http-adapter/interfaces';
import {isNull} from '@/utilites/type-guards/Primitives';
import {Nullable} from '@/utilites/utility-types';

export class SoonDeletedDomainCollection extends BaseCollection<typeof DomainModel> {
	protected mapByName: Nullable<Map<DomainModel['name'], DomainModel>> = null;
	constructor() {
		super(DomainModel);
	}

	private createMapByName() {
		if (isNull(this.mapByName) && this.items.length) {
			this.mapByName = this.items.reduce<Map<DomainModel['name'], DomainModel>>((map, domain) => {
				if (domain.name) {
					map.set(domain.name, domain);
				}
				return map;
			}, new Map());
		}
	}

	public getByName(domainName: string): DomainModel | undefined {
		return this.mapByName?.get(domainName);
	}

	public async load() {
		if (!this.isLoaded) {
			const response = await this.apiService.getSoonDeletedDomains();
			const responseExtractor = new HttpAdapterResponseExtractor(response);

			if (responseExtractor.isSuccessResponse) {
				this.apply(response as IHttpAdapterResponse<GetDomainsSuccessResponse>);
				this.createMapByName();
				this.isLoaded = true;
			}
		}
	}
}
