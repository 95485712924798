import {MakeCommonResponseInterface} from '@/services/http-adapter/interfaces';

export enum Feature {
	DomainSelfhostedDisabled = 'domain-selfhosted-disabled',
	JivoSiteLandings = 'jivosite-landings',
	JivoSiteVerify = 'jivosite-verify',
	CompanyNameTestBiz = 'company-name-test-biz',
	PhoneRequiredForDomainCreate = 'phone-required-for-domain-create',
}

export interface GetMeSuccessResponse {
	features: Feature[];
	has_verified_phone: boolean;
	is_trial_available: boolean;
	is_mail: boolean;
	type?: any;
	email?: string;
	phone?: string | number;
}

export interface GetMeErrorResponse {}

export type TGetMeResponse = MakeCommonResponseInterface<GetMeSuccessResponse, GetMeErrorResponse>;
