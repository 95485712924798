import {action, computed, observable} from 'mobx';

import type {
	CreateProjectFormValidationConfig,
	CreateProjectFormValidationResult,
} from '@/mobx/auxiliary-models/form-base/interfaces';
import {getPropertyName} from '@/utilites/get-property-name';

export class FormViewModelBase {
	private validationConfig: CreateProjectFormValidationConfig<this> | null = null;

	@observable
	private validationResult: CreateProjectFormValidationResult | null = null;

	public setValidations(config: CreateProjectFormValidationConfig<this>) {
		this.validationConfig = config;
	}

	public getValidationResult = () => {
		return this.validationResult;
	};

	@action
	public resetValidationResult = () => {
		if (this.validationResult !== null) {
			this.validationResult = null;
		}
	};

	@action
	protected setValidationResult(propName: string, result: [boolean, string]) {
		if (!this.validationResult) {
			this.validationResult = {};
		}
		if (!this.validationResult[propName]) {
			this.validationResult[propName] = [];
		}
		this.validationResult[propName].push(result);
	}

	public checkIfFieldValid(propGetter: () => any): boolean {
		const propName = getPropertyName(propGetter);
		return (
			!this.validationResult?.[propName] ||
			this.validationResult?.[propName].every((x) => x[0] === true)
		);
	}

	@computed
	public get isFormValid(): boolean {
		return Object.values(this.validationResult)?.every((x) => x.every(([isValid]) => isValid === true));
	}

	public getErrorMessageFirst(propGetter: () => any): string | undefined {
		return this.getErrorMessages(propGetter)?.[0];
	}

	public getErrorMessages(propGetter: () => any): string[] | undefined {
		const propName = getPropertyName(propGetter);
		return this.validationResult?.[propName]
			?.filter(([isValid]) => !isValid)
			?.map(([_, message]) => message);
	}

	@action
	public validate(): boolean {
		if (!this.validationConfig) {
			return null;
		}

		this.resetValidationResult();

		this.validationConfig.forEach(([propGetter, validateFunc]) => {
			const propName = getPropertyName(propGetter);
			const propValue = this[propName];
			if (Array.isArray(validateFunc)) {
				validateFunc.forEach((validateFuncOne) => {
					const validationResult = validateFuncOne(propValue, propName as keyof this, this);
					if (validationResult) {
						this.setValidationResult(propName, validationResult);
					}
				});
			} else {
				const validationResult = validateFunc(propValue, propName as keyof this, this);
				if (validationResult) {
					this.setValidationResult(propName, validationResult);
				}
			}
		});

		return (
			this.validationResult === null ||
			Object.keys(this.validationResult).length === 0 ||
			Object.values(this.validationResult).every((x) => x.every((y) => y[0] === true))
		);
	}
}
