import features, {Source} from '@mail/features';

import {PROD_HOST} from '@/constants';

/*  This is hack for pass features through the URL
	example: https://master-biz-dev.stands.dev.biz.corp.devmail.ru/?ftrs=is_sng
	WORD ONLY IN LOCAL BUILD AND DEV_STEND
	Sota does it itself, but there is no sota
 */
export const getInlineFtrsFeatures = () => {
	try {
		if (self.location.origin !== PROD_HOST) {
			// eslint-disable-next-line compat/compat
			const url = new URL(self.location.href);
			const parsedUrl = url.searchParams;
			const featuresString = parsedUrl.get('ftrs');
			if (featuresString) {
				features.import(featuresString, Source.GetParam);
			}
		}
	} catch (error) {
		console.error(error);
	}
};
