import {ServiceBase} from '@/services/ServiceBase';
import {Nullable} from '@/utilites/utility-types';

import {COOKIE_ATTRIBUTES} from './constants';
import {ICookieService} from './ICookieService';
import {CookieAttributes, CookieValue} from './interfaces';
import {formCookieValue} from './utils';

export class CookieService extends ServiceBase implements ICookieService {
	get(key: string): Nullable<string> {
		if (!key) {
			return null;
		}

		if (!document) {
			throw new Error('Cannot access cookies: document is not defined');
		}

		const regExp = new RegExp(`(?:^|; )${key}=([^;]*)`);
		const matches = document.cookie.match(regExp);

		return matches ? decodeURIComponent(matches[1]) : null;
	}

	has(key: string): boolean {
		return this.get(key) !== null;
	}

	set(key: string, value: CookieValue, options: CookieAttributes = {}): boolean {
		if (!key || COOKIE_ATTRIBUTES.includes(key.trim().toLowerCase())) {
			return false;
		}

		if (options.expires instanceof Date) {
			options.expires = options.expires.toUTCString();
		}

		document.cookie = formCookieValue(key, value, options);

		return true;
	}

	remove(key: string): boolean {
		const cookie = this.get(key);

		if (cookie === null) {
			return false;
		}

		return this.set(key, '', {
			'max-age': -1,
		});
	}
}
