//  abColor: 'ab-white' | 'ab-black'

export const BLACK_COLOR = 'black';
export const WHITE_COLOR = 'white';

export function getGlobalABColor() {
	const color = window?.abColor || '';
	if (color.includes(BLACK_COLOR)) {
		return BLACK_COLOR;
	} else if (color.includes(WHITE_COLOR)) {
		return WHITE_COLOR;
	}
	return null;
}
