import {interfaces} from 'inversify';

export type IServiceFactory = <T, R extends T>(serviceIdentifier: interfaces.ServiceIdentifier<T>) => R;

export function IServiceFactory<T, R extends T>(_: interfaces.ServiceIdentifier<T>): R {
	throw new Error('IServiceFactory is not implemented');
}

export const serviceFactory: interfaces.FactoryCreator<interfaces.ServiceIdentifier<any>> =
	function serviceFactory(context) {
		const container = context.container;

		return function <T, SI extends interfaces.ServiceIdentifier<T>>(serviceIdentifier: SI): T {
			return container.get(serviceIdentifier);
		};
	};
