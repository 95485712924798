import classNames from 'classnames';
import {
	type ChangeEventHandler,
	type FocusEventHandler,
	type HTMLInputTypeAttribute,
	type Ref,
	forwardRef,
	useCallback,
	useEffect,
	useMemo,
	useRef,
} from 'react';
import ReactInputMask from 'react-input-mask';

import {resolveIdAndName} from '../../helpers/resolve-id-and-name';
import styles from './Input.module.css';
import {type AutocompleteType, type inputModeType, INPUT_STYLES} from './types';

export interface InputProps {
	elRef?: Ref<HTMLInputElement | ReactInputMask>;
	autocomplete?: AutocompleteType;
	maxlength?: number;
	fullWidth?: boolean;
	type?: HTMLInputTypeAttribute;
	inputMode?: inputModeType;
	value?: number | string;

	id: string;
	name?: string;
	placeholder?: string;
	disabled?: boolean;
	readOnly?: boolean;
	invalid?: boolean;

	className?: string;
	onChange?: ChangeEventHandler<HTMLInputElement>;
	onClick?: () => void;
	onKeyDown?: () => void;
	onFocus?: () => void;
	onBlur?: FocusEventHandler<HTMLInputElement>;
	mask?: string | Array<string | RegExp>;
	ariaDescribedBy?: string;
	nameplate?: string;
	autoFocus?: boolean;
	inputStyle?: INPUT_STYLES;
}

function hasMaxLength(type) {
	return ['text', 'email', 'search', 'password', 'tel', 'url'].includes(type);
}

export const Input = forwardRef<HTMLInputElement | ReactInputMask, InputProps>(
	(
		{
			autocomplete = 'off',
			maxlength = 255,
			type = 'text',
			inputMode = null,
			value,
			id,
			name = null,
			placeholder,
			disabled = false,
			readOnly,
			invalid = false,
			nameplate,
			autoFocus,

			className = null,
			onChange,
			onClick,
			onKeyDown,
			onFocus,
			onBlur,
			mask,
			ariaDescribedBy = null,
			inputStyle = INPUT_STYLES.DEFAULT,
		},
		ref,
	) => {
		const inputRef = useRef<HTMLInputElement | ReactInputMask | null>(null);
		const withPhoneMask = useMemo(() => type === 'tel' && mask, [mask, type]);

		useEffect(() => {
			if (autoFocus) {
				setTimeout(() => {
					if (inputRef.current) {
						(inputRef.current as HTMLInputElement).focus();
					}
				}, 0);
			}
		}, [autoFocus]);

		const handleRefs = useCallback(
			(inputEl: HTMLInputElement | ReactInputMask | null) => {
				if (ref) {
					if (typeof ref === 'function') {
						ref(inputEl);
					} else if (ref && 'current' in ref) {
						ref.current = inputEl;
					}
				}
				inputRef.current = inputEl;
			},
			[ref, inputRef],
		);

		const commonProps = {
			autoComplete: autocomplete,
			...(withPhoneMask ? {} : {maxLength: hasMaxLength(type) ? maxlength : undefined}),
			type,
			inputMode,
			defaultValue: value,
			placeholder,
			className: classNames(className, styles.inputRoot, {
				[styles.stateInvalid]: invalid,
			}),
			disabled,
			readOnly,
			onChange,
			onClick,
			onKeyDown,
			onFocus,
			onBlur,
			'aria-describedby': ariaDescribedBy,
			'aria-invalid': invalid,
			...resolveIdAndName(id, name),
		};

		return (
			<div
				className={classNames(styles.wrapper, {
					[styles.appearanceBlue]: inputStyle === INPUT_STYLES.BLUE,
				})}
			>
				{withPhoneMask ? (
					<ReactInputMask ref={handleRefs} mask={mask} autoFocus={autoFocus} {...commonProps} />
				) : (
					<input ref={handleRefs} {...commonProps} />
				)}
				{nameplate && <div className={styles.nameplate}>{nameplate}</div>}
			</div>
		);
	},
);

Input.displayName = 'Input';
