import {useEffect} from 'react';

export const activeUser = {
	fullName: '',
	email: '',
};

export const useActiveUser = () => {
	useEffect(() => {
		window?.__PH?.accountService?.onAccountDataUpdate?.(({data}: any) => {
			activeUser.email = data.activeUser;
			activeUser.fullName = data.activeUserFullName;
		});
	}, []);
};
