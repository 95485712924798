// eslint-disable-next-line @typescript-eslint/ban-types
export function isObject<T extends object>(target: any): target is T {
	return target !== null && typeof target === 'object' && !Array.isArray(target);
}

export function isArray<T = any>(target: any): target is T[] {
	return Array.isArray(target);
}

export function isMap<K = any, V = any>(target: any): target is Map<K, V> {
	return target instanceof Map;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function instanceOf<T extends Function>(target: any, type: T): target is T {
	return target instanceof type;
}

export function isPromise<TX>(target: TX | Promise<TX>): target is Promise<TX> {
	return target instanceof Promise;
}

export function isDate(target: any): target is Date {
	return target instanceof Date;
}

export function isValidDate(target: any): target is Date {
	return target instanceof Date && !isNaN(target as unknown as number);
}

export function isError<T extends Error>(target: any): target is T {
	return target !== null && target instanceof Error;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function isPlainObject<T extends object>(target: any): target is T {
	if (target === null || typeof target !== 'object') {
		return false;
	}
	const proto = Object.getPrototypeOf(target);
	return proto === Object.prototype || proto === null;
}

export const isCallable = (maybeFunction: any): maybeFunction is (...args: any[]) => void =>
	typeof maybeFunction === 'function';
