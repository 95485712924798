import {ProjectHrefs} from '@/constants';
import {ConfigParams} from '@/services/config/interfaces';
import {ServiceBase} from '@/services/ServiceBase';

import {VK_WS_CONFIG} from './const';
import {IConfigService} from './IConfigService';

export class ConfigService extends ServiceBase implements IConfigService {
	page: ProjectHrefs;
	get config(): ConfigParams {
		if (!this.page) {
			throw new Error('page is not defined');
		}

		return VK_WS_CONFIG;
	}
}
