import {useEffect, useRef} from 'react';

export function useFocusTrack(track = true) {
	const ref = useRef(null);

	useEffect(
		function () {
			if (track === true) {
				ref.current = document.activeElement;
			}

			return function () {
				if (track === true && ref.current !== null) {
					ref.current.focus();
					ref.current = null;
				}
			};
		},
		[track],
	);
}
