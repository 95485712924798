import classNames from 'classnames';
import {observer} from 'mobx-react';
import {type NextRouter, withRouter} from 'next/router';
import {type SyntheticEvent, Component, createRef} from 'react';

import {Button, BUTTON_STYLES} from '@/components/base/button/Button';
import {DialogTitle} from '@/components/base/dialog/DialogTitle';
import {Input} from '@/components/base/input/Input';
import {Link} from '@/components/base/link/Link';
import {Typography, VARIANTS} from '@/components/base/typography/Typography';
import {
	BIZML_DOMAIN_SUFFIX,
	INPUT_COMPANY_NAME_MAX_LENGTH,
} from '@/components/common/forms/create-project-form/const';
import {DomainType} from '@/components/common/forms/create-project-form/interfaces';
import {LINKS} from '@/constants';
import {injectProperty} from '@/container/injections';
import {SoonDeletedDomainCollection} from '@/mobx/business-collections/domain/SoonDeletedDomainCollection';
import {MeStore} from '@/mobx/business-models/me/MeModel';
import {PSRegStatusStore} from '@/mobx/business-models/ps-registration-status/PSRegStatusModel';
import {Feature} from '@/services/api/interfaces/me';
import {IFeatureService} from '@/services/feature/IFeatureService';
import {XrayEventId} from '@/services/metrics/constants';
import {IMetricsService} from '@/services/metrics/IMetricsService';
import {isString} from '@/utilites/type-guards/Primitives';
import {typography} from '@/utilites/typography/typography';

import styles from './CreateProjectForm.module.css';
import {CreateProjectFormViewModel} from './CreateProjectFormViewModel';

enum FormElements {
	DomainType = 'domainType',
	OwnDomainName = 'ownDomainName',
	BizDomainName = 'bizDomainName',
	AgreeWithPolicy = 'agreeWithPolicy',
}

interface CreateProjectFormProps {
	onValidSubmit?: () => void;
	onClick?: () => void;
	onClose?: () => void;
	onLoginChangeClick?: () => void;
	router: NextRouter;
}

interface CreateProjectFormState {
	selfHostedEnabled: boolean;
}

@observer
class CreateProjectForm extends Component<CreateProjectFormProps, CreateProjectFormState> {
	@injectProperty(IFeatureService) protected readonly featureService!: IFeatureService;
	@injectProperty(IMetricsService) protected readonly metricsService!: IMetricsService;
	protected viewModel: CreateProjectFormViewModel;

	protected ownDomainInputRef = createRef<HTMLInputElement>();
	protected bizDomainInputRef = createRef<HTMLInputElement>();

	constructor(props: CreateProjectFormProps) {
		super(props);
		this.viewModel = new CreateProjectFormViewModel({
			router: props.router,
			domainCollection: new SoonDeletedDomainCollection(),
		});

		this.viewModel.setValidations([
			[
				() => this.viewModel.ownDomainName,
				[
					(value, propName, viewModel) => {
						if (!viewModel.checkIfDomainTypeSelected(DomainType.OwnDomain)) {
							return;
						}
						const isValid = isString(value) && value !== '';
						return [isValid, 'Введите название домена'];
					},
				],
			],
			[
				() => this.viewModel.bizDomainName,
				[
					(value, propName, viewModel) => {
						if (!viewModel.checkIfDomainTypeSelected(DomainType.BizDomain)) {
							return;
						}
						const isValid = isString(value) && value !== '';
						return [
							isValid,
							this.viewModel.isOpenedCompanyNameForm
								? 'Введите название'
								: 'Введите название домена',
						];
					},
				],
			],
		]);
		const isTrialAvailable = MeStore.is_trial_available && !PSRegStatusStore.isPartnerUser();
		const hasFeature = this.featureService.checkIfFeatureExist(Feature.DomainSelfhostedDisabled);
		const isSelfHostedEnabled = !hasFeature && isTrialAvailable;

		this.state = {
			selfHostedEnabled: isSelfHostedEnabled,
		};
		this.viewModel.setDomainType(isSelfHostedEnabled ? DomainType.BizDomain : DomainType.OwnDomain);
	}

	async componentDidMount() {
		this.setFocusDomainInput(this.viewModel.domainType);
	}

	handleDomainTypeChange = () => {
		const value =
			this.viewModel.domainType === DomainType.OwnDomain ? DomainType.BizDomain : DomainType.OwnDomain;
		this.viewModel.setDomainType(value);
		this.setFocusDomainInput(value);
		this.metricsService.sendEvent({
			xrayEvents: [
				{
					name: XrayEventId.clickChangeDomainType,
					params: {
						domainType: value,
					},
				},
			],
		});
	};

	setFocusDomainInput(domainType: DomainType) {
		setTimeout(() => {
			switch (domainType) {
				case DomainType.OwnDomain:
					this.ownDomainInputRef.current?.focus();
					break;
				case DomainType.BizDomain:
					this.bizDomainInputRef.current?.focus();
					break;
			}
		}, 0);
	}

	handleFormSubmit = async (event: SyntheticEvent) => {
		event.preventDefault();
		const isFormValid = this.viewModel.validate();

		if (!isFormValid) {
			this.viewModel.handleFormSubmitMetricSend('invalid_form');
			return;
		}

		if (this.viewModel.isPartners) {
			await this.viewModel.submitPartnersBizFormData();
		} else if (this.viewModel.isOpenedOwnDomainForm) {
			await this.viewModel.submitOwnFormData();
		} else if (this.viewModel.isOpenedCompanyNameForm) {
			await this.viewModel.submitCompanyBizFormData();
		} else {
			await this.viewModel.submitBizFormData();
		}

		// NOTE: вторая проверка на валидность нужна потому, что после отправки формы могут быть ошибки с бэкенда
		if (this.viewModel.isFormValid) {
			this.props.onValidSubmit();
		}
	};

	handleAnyChange = () => {
		this.viewModel.resetValidationResult();
	};

	renderErrorMessageFirst = (propGetter: () => any) => {
		const errorMessages = this.viewModel.getErrorMessages(propGetter);
		if (errorMessages?.length) {
			const errorMessage = errorMessages[0];
			return <div className={styles.fieldError}>{errorMessage}</div>;
		}
		return null;
	};

	getDialogTitle = (): string => {
		if (this.viewModel.isPartnersWithoutDefaultDomainHasOwnDomain) {
			return 'Укажите домен компании';
		}

		if (this.viewModel.isPartnersWithoutDefaultDomain) {
			return 'Создайте домен или используйте свой';
		}

		if (this.viewModel.isPartnersWithDefaultDomain) {
			return 'Проверьте домен компании';
		}

		if (this.viewModel.isOpenedOwnDomainForm) {
			return 'Напишите домен компании';
		}

		if (this.viewModel.isOpenedCompanyNameForm) {
			return 'Как называется ваша компания?';
		}

		if (this.viewModel.isOpenedTestDomainForm) {
			return 'Создайте домен и пользуйтесь бесплатно 30 дней';
		}

		return 'Войдите в аккаунт mail.ru, чтобы создать домен';
	};

	getActionBtnText = () => {
		if (this.viewModel.isOpenedOwnDomainForm || this.viewModel.isOpenedCompanyNameForm) {
			return 'Продолжить';
		}

		if (this.viewModel.isOpenedTestDomainForm || this.viewModel.isPartnersWithoutDefaultDomain) {
			return 'Создать';
		}

		return 'Сменить аккаунт';
	};

	getAdditionalBtnText = () => {
		return this.viewModel.isOpenedOwnDomainForm ? 'Создать новый' : 'У меня есть домен';
	};

	// eslint-disable-next-line max-lines-per-function
	render() {
		const disabled = this.viewModel.inProgress;
		const isMail = MeStore.is_mail;
		const isOpenedOwnDomainForm = this.viewModel.isOpenedOwnDomainForm;
		const isOpenedCompanyNameForm = this.viewModel.isOpenedCompanyNameForm;
		const isOpenedNotMailForm = this.viewModel.isOpenedNotMailForm;
		const shouldShowAdditionalButton = this.state.selfHostedEnabled && !isOpenedCompanyNameForm;

		return (
			<form onSubmit={this.handleFormSubmit} onChange={this.handleAnyChange} className={styles.form}>
				<DialogTitle>{typography(this.getDialogTitle())}</DialogTitle>

				{isOpenedOwnDomainForm ? (
					<>
						<label htmlFor={`${FormElements.DomainType}-${DomainType.OwnDomain}`}>
							<Typography variant={VARIANTS.text15} className={styles.label}>
								Нужно будет подтвердить, что домен принадлежит вам
							</Typography>
						</label>

						<div
							className={styles.inputWrapper}
							onClick={() => {
								this.viewModel.setDomainType(DomainType.OwnDomain);
								this.viewModel.resetValidationResult();
								this.setFocusDomainInput(DomainType.OwnDomain);
							}}
						>
							<Input
								id={FormElements.OwnDomainName}
								ref={this.ownDomainInputRef}
								placeholder="Введите домен"
								value={this.viewModel.ownDomainName}
								className={classNames(styles.control, styles.domainsControl, styles.input)}
								disabled={
									disabled ||
									this.viewModel.checkIfDomainInputDisabled(DomainType.OwnDomain) ||
									this.viewModel.isPartnersWithDefaultDomain
								}
								onChange={(event) => this.viewModel.setOwnDomainName(event.target?.value)}
								invalid={
									!this.viewModel.checkIfFieldValid(() => this.viewModel.ownDomainName)
								}
							/>
							{this.renderErrorMessageFirst(() => this.viewModel.ownDomainName)}
						</div>
					</>
				) : (
					<>
						{!isOpenedCompanyNameForm && (
							<label htmlFor={`${FormElements.DomainType}-${DomainType.BizDomain}`}>
								{isMail ? (
									<Typography variant={VARIANTS.text15} className={styles.label}>
										Домен будет частью вашего корпоративного адреса. Изменить придуманный
										домен позже не получится
									</Typography>
								) : (
									<Typography variant={VARIANTS.text15} className={styles.label}>
										Для личного кабинета нужен домен — создать новый можно только с
										аккаунтом mail.ru. Смените аккаунт или используйте свой домен
									</Typography>
								)}
							</label>
						)}

						{isMail && (
							<div
								className={styles.inputWrapper}
								onClick={() => {
									this.viewModel.setDomainType(DomainType.BizDomain);
									this.viewModel.resetValidationResult();
									this.setFocusDomainInput(DomainType.BizDomain);
								}}
							>
								<Input
									id={FormElements.BizDomainName}
									ref={this.bizDomainInputRef}
									placeholder={
										isOpenedCompanyNameForm ? 'Название компании' : 'Придумайте домен'
									}
									value={this.viewModel.bizDomainName}
									maxlength={
										isOpenedCompanyNameForm ? INPUT_COMPANY_NAME_MAX_LENGTH : undefined
									}
									className={classNames(
										styles.control,
										styles.domainsControl,
										styles.input,
										{[styles.withNameplate]: !isOpenedCompanyNameForm},
									)}
									disabled={
										disabled ||
										this.viewModel.checkIfDomainInputDisabled(DomainType.BizDomain)
									}
									onChange={(event) => this.viewModel.setBizDomainName(event.target?.value)}
									invalid={
										!this.viewModel.checkIfFieldValid(() => this.viewModel.bizDomainName)
									}
									nameplate={
										!isOpenedCompanyNameForm ? `.${BIZML_DOMAIN_SUFFIX}` : undefined
									}
								/>
								{this.renderErrorMessageFirst(() => this.viewModel.bizDomainName)}
							</div>
						)}
					</>
				)}

				{!isOpenedNotMailForm && (
					<div className={styles.checkboxesWrapper}>
						<label htmlFor={FormElements.AgreeWithPolicy}>
							<Typography variant={VARIANTS.text15} className={styles.checkboxLabel}>
								Нажимая «{this.getActionBtnText()}», вы соглашаетесь с{' '}
								<Link
									href={LINKS.bizTermsofuse}
									target={'_blank'}
									className={styles.termsLink}
								>
									Условиями использования
								</Link>{' '}
								сервиса и с{' '}
								<Link href={LINKS.bizPrivacy} target={'_blank'} className={styles.termsLink}>
									Политикой конфиденциальности
								</Link>
							</Typography>
						</label>
					</div>
				)}

				<div className={styles.buttonWrapper}>
					<Button
						className={styles.button}
						type={!isOpenedNotMailForm ? 'submit' : 'button'}
						onClick={() => {
							if (isOpenedNotMailForm) {
								this.props.onLoginChangeClick();
							}
							this.props.onClick?.();
						}}
						disabled={disabled}
						fullWidth
					>
						{this.getActionBtnText()}
					</Button>

					{shouldShowAdditionalButton && (
						<Button
							className={styles.button}
							onClick={this.handleDomainTypeChange}
							disabled={disabled}
							style={BUTTON_STYLES.CONTRAST}
							fullWidth
						>
							{this.getAdditionalBtnText()}
						</Button>
					)}
				</div>
			</form>
		);
	}
}
export default withRouter<CreateProjectFormProps>(CreateProjectForm);
