type LinkPropsResult = {
	href: string;
	target: '_blank' | null;
	rel: 'noopener noreferrer' | null;
};

export function getLinkProps(href: string, external = false): LinkPropsResult {
	return {
		href,
		target: external ? '_blank' : null,
		rel: external ? 'noopener noreferrer' : null,
	};
}
