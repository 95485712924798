import {
	ExtractErrorResponseInterface,
	ExtractSuccessResponseInterface,
	IHttpAdapterResponse,
} from '@/services/http-adapter/interfaces';
import {isArray} from '@/utilites/type-guards/Complex';
import {isDefined, isString} from '@/utilites/type-guards/Primitives';
import {MaybeMissing} from '@/utilites/utility-types';

const DEFAULT_ERROR_MESSAGE = 'Во время выполнения запроса произошла ошибка';

// todo: заполнить мапку с ошибками
const ERROR_MESSAGE_BY_HTTP_STATUS_CODE = {
	500: 'Что-то пошло не так',
	400: 'Отправлены неверные данные',
	404: 'Ресурс не найден',
};

export class HttpAdapterResponseExtractor<
	TResponse extends IHttpAdapterResponse<any> = IHttpAdapterResponse<any>,
> {
	protected readonly response: TResponse;
	protected defaultErrorMessage: MaybeMissing<string>;

	constructor(response: TResponse, defaultErrorMessage?: string) {
		this.response = response;
		this.defaultErrorMessage = defaultErrorMessage;
	}

	public get isSuccessResponse(): boolean {
		return this.response.status >= 200 && this.response.status < 300;
	}

	public get isErrorResponse(): boolean {
		return !this.isSuccessResponse;
	}

	public getResponseData(): ExtractSuccessResponseInterface<TResponse['data']> {
		return this.response.data;
	}

	public getErrorData(): ExtractErrorResponseInterface<TResponse['data']> {
		return this.response.data;
	}

	public getErrorMessage(propName?: string): string | undefined {
		if (!this.isErrorResponse) {
			return undefined;
		}
		try {
			const data = this.response.data;
			const internalData = data.data;
			if (isDefined(internalData) && isDefined(internalData[propName])) {
				if (isString(internalData[propName])) {
					return internalData[propName];
				}
				if (isArray(internalData[propName])) {
					return internalData[propName][0];
				}
			}

			if (isDefined(data?.message)) {
				return data.message;
			}

			return (
				ERROR_MESSAGE_BY_HTTP_STATUS_CODE[this.response.status] ??
				this.defaultErrorMessage ??
				this.response.statusText ??
				DEFAULT_ERROR_MESSAGE
			);
		} catch (error) {
			return DEFAULT_ERROR_MESSAGE;
		}
	}
}
